import { Col } from "antd";

import { IAttachment, TSendOtherOossaTaskBody, StateNamesEnum, TaskTypesIdsEnum } from "core/app/api/interfaces";
import { useUnitId } from "core/shared/unit/useUnitId";
import { useGetTaskStatesQuery, useCreateOossaTaskMutation } from "core/app/api";
import dayjs, { apiDateFormat, apiDateTimeFormat } from "core/shared/tools/dayjs";
import { IDictionaryItem } from "core/shared/interfaces";
import EffortField from "core/widgets/CreateTaskModal/Fields/EffortField";
import AttachmentsField from "core/widgets/CreateTaskModal/Fields/AttachmentsField";
import ShiftSelector from "core/widgets/CreateTaskModal/Fields/ShiftSelector";
import DescriptionField from "core/widgets/CreateTaskModal/Fields/DescriptionField";
import WorkTypeField from "core/widgets/CreateTaskModal/Fields/WorkTypeField";

import { CreateTaskBaseForm } from "../../CreateTaskBaseForm";

import { CustomerDepartment } from "./Fields/CustomerDepartment";
import { Object } from "./Fields/Object";
import { System } from "./Fields/System";
import { Node } from "./Fields/Node";
import { Equipment } from "./Fields/Equipment";

export function Oossa() {
  const [unitId] = useUnitId();
  const [sendTask, sendTaskResult] = useCreateOossaTaskMutation();
  const { data: states } = useGetTaskStatesQuery("");

  const initialValues = {
    equipmentId: undefined,
    attachments: [],
    effort_duration: "00:00:00",
    teamId: null,
    shiftNumber: 1 as 1 | 2 | 3,
    plannedDate: "",
    customerDepartmentId: null,
    objectId: undefined,
    systemComplexId: null,
    workTypeId: null,
    systemId: null,
    nodeId: undefined,
    description: "",
  };

  const createTask = async (values: typeof initialValues) => {
    if (!unitId || !states) {
      return Promise.reject();
    }

    const state = states.results.find((item: IDictionaryItem) => item.name === StateNamesEnum.New);

    const body: TSendOtherOossaTaskBody = {
      type: TaskTypesIdsEnum.OTHER,
      state_id: state?.id,
      unit_id: unitId,
      work_type_id: values.workTypeId,
      customer_department_id: values.customerDepartmentId,
      effort_duration: values.effort_duration,
      oossa_equipment_id: values.equipmentId,
      oossa_tech_node_id: values.nodeId,
      system_id: values.systemId,
      request_creation_datetime: dayjs().format(apiDateTimeFormat),
      oossa_object_id: values.objectId,
      request_description: values.description,
    };
    if (values.teamId) {
      body.team_id = values.teamId;
    }
    if (values.shiftNumber) {
      body.shift_number = values.shiftNumber;
    }
    if (values.plannedDate) {
      body.planned_date = dayjs(values.plannedDate).format(apiDateFormat);
    }
    if (values.attachments) {
      body.attachments = {
        files_by_task: values.attachments as IAttachment[],
        work_place: [],
        before_or_in_progress: [],
        after_completion: [],
      };
    }

    return sendTask(body).unwrap();
  };

  return (
    <CreateTaskBaseForm
      initialValues={initialValues}
      createTaskFunction={createTask}
      createTaskIsLoading={sendTaskResult.isLoading}
      shouldCancelSubmit={!unitId || !states}
    >
      <Col span={12}>
        {/* <WorkType /> */}
        <WorkTypeField />
      </Col>
      <Col span={12}>
        <CustomerDepartment />
      </Col>
      <Col span={12}>
        <Object />
      </Col>
      <Col span={12}>
        <System />
      </Col>
      <Col span={12}>
        <Node />
      </Col>
      <Col span={12}>
        <Equipment />
      </Col>
      <Col span={24}>
        <DescriptionField />
      </Col>
      <Col span={12}>
        <EffortField name="effort_duration" required />
      </Col>
      <Col span={24}>
        <AttachmentsField />
      </Col>
      <Col className="mb-1 mt-1" span={24}>
        <ShiftSelector />
      </Col>
    </CreateTaskBaseForm>
  );
}
