import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TaskTypesIdsEnum, IWorkRegime } from "core/app/api/interfaces";
import { UnitServiceCodesEnum } from "core/shared/unit/types";

interface ITasksFilter {
  equipment_id?: number;
  equipment_type_id?: number;
  is_mounted?: boolean;
  location_id?: number;
  system_id?: number;
  type?: null | TaskTypesIdsEnum.MAINTENANCE | TaskTypesIdsEnum.RECOVERY;
  request_priority?: number;
  transport_id?: number;
}

interface IMain {
  shift: IWorkRegime | null;
  unitId: number | null;
  customer: number | null;
  equipmentId: number | null;
  customerDepartmentId: number | null;
  locationId: number | null;
  tasksFilter: ITasksFilter;
  teamManagerOpened: boolean;
  taskSidebarOpened: boolean;
  fullScreen: boolean;
  selectedMonth: number;
  unitDepartmentId: null | number;
  serviceCode: null | UnitServiceCodesEnum;
}

export const initialTaskFilter = {};

const initialState: IMain = {
  shift: null,
  unitId: null,
  customer: 1,
  equipmentId: null,
  customerDepartmentId: null,
  locationId: null,
  tasksFilter: initialTaskFilter,
  teamManagerOpened: false,
  taskSidebarOpened: false,
  fullScreen: false,
  selectedMonth: 0,
  unitDepartmentId: null,
  serviceCode: null,
};

export const MainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setShift(state, action: PayloadAction<IWorkRegime | null>) {
      state.shift = action.payload;
    },
    setUnitId(state, action: PayloadAction<{ id: number | null; code: UnitServiceCodesEnum | null }>) {
      const { id, code } = action.payload;
      state.unitId = id;
      state.serviceCode = code;
      state.customerDepartmentId = null;
      state.locationId = null;
    },
    setCustomerDepartmentId(state, action: PayloadAction<number | null>) {
      state.customerDepartmentId = action.payload;
    },
    setLocationId(state, action: PayloadAction<number | null>) {
      state.locationId = action.payload;
    },
    setCustomer(state, action: PayloadAction<number | null>) {
      state.customer = action.payload;
    },
    setEquipmentId(state, action: PayloadAction<number | null>) {
      state.equipmentId = action.payload;
    },
    setTasksFilter(state, action: PayloadAction<ITasksFilter>) {
      state.tasksFilter = action.payload;
    },
    setTeamManagerOpened(state, action: PayloadAction<boolean>) {
      state.teamManagerOpened = action.payload;
    },
    reset() {
      return initialState;
    },
    setTaskSidebarOpened(state, action: PayloadAction<boolean>) {
      state.taskSidebarOpened = action.payload;
    },
    setFullScreen(state, action: PayloadAction<boolean>) {
      state.fullScreen = action.payload;
    },
    setSelectedMonth(state, action: PayloadAction<number>) {
      state.selectedMonth = action.payload;
    },
    setUnitDepartmentId(state, action: PayloadAction<number | null>) {
      state.unitDepartmentId = action.payload;
    },
  },
});

export const {
  setCustomerDepartmentId,
  setShift,
  setUnitId,
  setTasksFilter,
  setTeamManagerOpened,
  setCustomer,
  reset,
  setEquipmentId,
  setTaskSidebarOpened,
  setFullScreen,
  setSelectedMonth,
  setUnitDepartmentId,
  setLocationId,
} = MainSlice.actions;
