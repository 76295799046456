import { ReactElement } from "react";

import { ITask, TaskDiscriminatorEnum } from "core/app/api/interfaces";
import { getHoursFromTime, formatDuration } from "core/shared/tools/dayjs";
import { formatHours } from "core/shared/main/tools/formatHours";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

/** Атрибут "Фактические трудозатраты" в карточке ТО. */
function AccumulatorElapsedTime({ task }: FieldProps): ReactElement {
  if (!task.accumulator_elapsed_time) {
    return <div />;
  }
  let value = formatHours(getHoursFromTime(task.accumulator_elapsed_time, false));
  if (![TaskDiscriminatorEnum.SLPI_TASK, TaskDiscriminatorEnum.LSO_TASK].includes(task.discriminator)) {
    value = formatDuration(task.accumulator_elapsed_time);
  }
  return <Field label="Фактические трудозатраты" value={value} />;
}

export { AccumulatorElapsedTime };
