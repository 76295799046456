import { useMemo } from "react";
import { Collapse, Form } from "antd";

import { Text } from "core/lib/Text/Text";
import { ICheckListItem } from "core/app/api/SGPS/interface";
import { groupWithOrder } from "core/shared/tools/groupWithOrder";

import { LocationEnum } from "..";

import { Power } from "./Power";

export interface ICheckListItemWithIndex extends ICheckListItem {
  index: number;
}

interface Props {
  visible: boolean;
  location: LocationEnum;
}

export function InsideOutside({ visible, location }: Props) {
  const checklists = Form.useWatch("checklist") as ICheckListItem[] | undefined;
  const checklistsWithIndex = useMemo(
    () =>
      checklists?.map((checklist: ICheckListItem, index: number) => ({
        ...checklist,
        index,
      })),
    [checklists]
  );

  const checklistsByLocation = useMemo(
    () =>
      checklistsWithIndex?.filter(
        (checklist: ICheckListItemWithIndex) => checklist.check.inside === (location === LocationEnum.inside)
      ),
    [checklistsWithIndex, location]
  );

  const checklistsByPower = groupWithOrder(checklistsByLocation, "check.power.name");

  return (
    <div style={{ display: visible ? "block" : "none" }}>
      <Collapse expandIconPosition="end" ghost>
        {Object.entries(checklistsByPower)?.map(([power, powerChecklists]) => (
          <Collapse.Panel
            forceRender
            key={power}
            header={
              <Text size="label1" boldness="semibold" transform="uppercase">
                {power.split("_")[0]}
              </Text>
            }
          >
            <Power checklists={powerChecklists} />
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
}
