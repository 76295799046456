import { ReactElement } from "react";

import { RoleEnum } from "core/shared/constants";
import { useHasUserOneOfSelectedRoles } from "core/shared/main/useHasUserOneOfSelectedRoles";

interface Props {
  roles: RoleEnum[];
  children: ReactElement;
}

export function CanIView({ roles, children }: Props): ReactElement {
  const isItemVisible = useHasUserOneOfSelectedRoles(roles);

  if (!isItemVisible) {
    return <div data-roles-required={roles.join(" ")} />;
  }

  return children;
}
