import { Form, InputNumber, message, Row, Space, Spin } from "antd";

import { useUpdateTask } from "core/shared/task/useUpdateTask";
import { Button } from "core/lib";
import { useDisabled } from "core/shared/task/useDisabled";

import { LsoMaintenanceContext } from "./LsoMaintenanceContext";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import { useLsoMaintenanceContextValues } from "./hooks";

function LsoTaskModal() {
  const contextValues = useLsoMaintenanceContextValues();
  const { task, setShowErrorMessage } = contextValues;
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task);
  const [form] = Form.useForm();
  const disabled = useDisabled();

  const handleSave = (values: any) => {
    const handleSuccess = () => {
      message.success(`Данные успешно сохранены`);
    };
    const handleError = (e: Error) => {
      message.error(`При сохранении данных произошла ошибка`);
      console.error(e);
    };

    setShowErrorMessage(false);
    updateTaskRequest(values).unwrap().then(handleSuccess).catch(handleError);
  };

  const handleValidationError = () => {
    setShowErrorMessage(true);
  };

  if (!task) {
    return (
      <Row align="middle" justify="center" style={{ minHeight: "300px" }}>
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <LsoMaintenanceContext.Provider value={contextValues}>
      {task && (
        <Form
          preserve
          scrollToFirstError
          initialValues={task}
          className="w-100 lso-task-modal"
          form={form}
          layout="vertical"
          onFinish={handleSave}
          onFinishFailed={handleValidationError}
        >
          <Form.Item name="id" label="id" hidden>
            <InputNumber />
          </Form.Item>
          <Space className="w-100" direction="vertical" size="large">
            <Header />
            <Content />
            <Footer />
            <Button
              className="large w-100"
              type="primary"
              size="large"
              htmlType="submit"
              disabled={disabled}
              loading={updateTaskResult.isLoading}
            >
              Сохранить
            </Button>
          </Space>
        </Form>
      )}
    </LsoMaintenanceContext.Provider>
  );
}

export default LsoTaskModal;
