import { ReactElement } from "react";
import clsx from "clsx";
import plugin from "dayjs/plugin/duration";

import dayjs from "core/shared/tools/dayjs";
import { isGroup } from "core/shared/dashboard";
import { useUnit } from "core/shared/unit/useUnit";
import { formatHours as formatHoursHH } from "core/shared/main/tools/formatHours";

import { ICellProps } from "..";

function ElapsedTime({ record }: ICellProps): ReactElement {
  let effort: plugin.Duration;
  let elapsedTimeTotal: plugin.Duration;
  const unit = useUnit();

  if (isGroup(record)) {
    if (!record.children?.length) {
      return <div />;
    }
    elapsedTimeTotal = record.children.reduce((duration, row) => {
      const { elapsed_time: elapsedTime, elapsed_time_multiplier: elapsedTimeMultiplier } = row;
      return duration.add({
        seconds: dayjs.duration(elapsedTime).asSeconds() * elapsedTimeMultiplier,
      });
    }, dayjs.duration(0));
    effort = record.children.reduce((duration, row) => duration.add(dayjs.duration(row.effort)), dayjs.duration(0));
  } else {
    const { elapsed_time: elapsedTime, elapsed_time_multiplier: elapsedTimeMultiplier } = record;
    elapsedTimeTotal = dayjs.duration({}).add({
      seconds: dayjs.duration(elapsedTime).asSeconds() * elapsedTimeMultiplier,
    });
    effort = dayjs.duration(record.effort);
  }
  const hours = Math.floor(elapsedTimeTotal.asHours());
  const minutes = elapsedTimeTotal.minutes();
  const timeStr = `${hours}ч. ${minutes}м.`;

  if (unit?.isSLPI()) {
    return (
      <div
        className={clsx({
          positive: elapsedTimeTotal.asSeconds() <= effort.asSeconds(),
          negative: elapsedTimeTotal.asSeconds() > effort.asSeconds(),
        })}
      >
        {formatHoursHH(elapsedTimeTotal.asHours())}
      </div>
    );
  }
  return (
    <div
      className={clsx({
        positive: elapsedTimeTotal.asSeconds() <= effort.asSeconds(),
        negative: elapsedTimeTotal.asSeconds() > effort.asSeconds(),
      })}
    >
      {timeStr}
    </div>
  );
}

export default ElapsedTime;
