import { ReactElement } from "react";
import { Typography as AntTypography, Tooltip } from "antd";
import { TextProps as AntTextProps } from "antd/lib/typography/Text";
import clsx from "clsx";

import "./styles.scss";

const { Text: AntText } = AntTypography;

export type TBoldness = "thin" | "light" | "regular" | "semibold" | "bold" | "extrabold" | "black";
export type TSize =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "body1"
  | "body2"
  | "body3"
  | "label1"
  | "label2"
  | "label3"
  | "label4";
type TAlign = "center" | "justify" | "left" | "right" | "start" | "end";
type TOpacity = "standard" | "semi-soft" | "soft" | "semi-hard";
export type TextTransform = "uppercase" | "capitalize";
export type TextColor = "black" | "gray1" | "gray2" | "gray3" | "white" | "blue" | "red";

interface ITextProps extends AntTextProps {
  boldness?: TBoldness;
  size?: TSize;
  align?: TAlign;
  opacity?: TOpacity;
  tooltip?: string;
  transform?: TextTransform;
  color?: TextColor;
}

export function Text({
  boldness,
  size,
  align,
  children,
  className,
  opacity,
  tooltip,
  transform,
  color,
  ...rest
}: ITextProps): ReactElement {
  if (!boldness) {
    boldness = "regular";
  }
  if (!size) {
    size = "body3";
  }
  if (!align) {
    align = "left";
  }
  if (!opacity) {
    opacity = "standard";
  }
  return (
    <AntText className={clsx([className, size, boldness, align, opacity, transform, color])} {...rest}>
      {tooltip && <Tooltip title={tooltip}>{children}</Tooltip>}
      {!tooltip && children}
    </AntText>
  );
}

export default Text;
