import { useLocation } from "react-router-dom";

import { AppRoutes } from "core/shared/constants.routes";
import { useCanChangePspaTask } from "core/shared/tasks/useCanChangeTask";
import { useUnit } from "core/shared/unit/useUnit";
import { TaskTypesIdsEnum } from "core/app/api/interfaces";

import { useTask } from "../useTask";

const DISABLED_LOCATIONS = [
  AppRoutes.tasks,
  AppRoutes.slpi,
  AppRoutes.oossa,
  AppRoutes.equipments,
  AppRoutes.base,
  AppRoutes.pss,
  AppRoutes.sgps,
  AppRoutes.maintenance,
] as const;

type TDisabledLocations = typeof DISABLED_LOCATIONS[number];

type TRuleException = Partial<Record<TDisabledLocations, boolean>>;

const getIsDisabledLocation = (loc: string): loc is TDisabledLocations =>
  DISABLED_LOCATIONS.includes(loc as TDisabledLocations);

export function useDisabledByLocation(consumer?: "SignatureButtons") {
  const { pathname } = useLocation();

  const unit = useUnit();
  const { data: task } = useTask();
  const canChangePsaTask = useCanChangePspaTask();

  const getTasksException = () => {
    const isCorrectService = Boolean(unit?.isPSPA());
    const isCorrectTaskType = task?.type !== TaskTypesIdsEnum.MAINTENANCE;
    return isCorrectService && isCorrectTaskType && canChangePsaTask;
  };

  /** Мap с вычисляемыми исключениями
   *  Ключ - location из DISABLED_LOCATIONS
   *  Значение - вычисляемое boolean исключение, если true - правило disabled не работает
   */
  const ruleExceptions: TRuleException = {
    [AppRoutes.tasks]: getTasksException(),
    [AppRoutes.maintenance]: consumer !== "SignatureButtons",
  };

  return getIsDisabledLocation(pathname) && !ruleExceptions[pathname];
}
