import { Form, Input } from "antd";

import { SlpiWorkTypeGroupEnum } from "core/app/api/interfaces";
import "./styles.scss";
import { useApiSlice } from "core/shared/main/useApiSlice";
import { useWorkType } from "core/shared/main/useWorkType";
import { ApiSliceEnum } from "core/app/api/enums";
import { LsoWorkTypeGroupEnum } from "core/app/api/LSO/enums";

export function Description() {
  const workTypeId = Form.useWatch("workTypeId");
  const apiSlice = useApiSlice();
  const workType = useWorkType(workTypeId, apiSlice);

  const getIsRequired = () => {
    if (apiSlice === ApiSliceEnum.slpi) {
      if (
        workType &&
        "group" in workType &&
        (workType?.group === SlpiWorkTypeGroupEnum.WORK || workType?.group === SlpiWorkTypeGroupEnum.RESEARCH)
      ) {
        return true;
      }
    }
    if (apiSlice === ApiSliceEnum.lso) {
      if (
        workType &&
        "group" in workType &&
        (workType?.group === LsoWorkTypeGroupEnum.WORK || workType?.group === LsoWorkTypeGroupEnum.RESEARCH)
      ) {
        return true;
      }
    }
    return false;
  };

  const required = getIsRequired();

  return (
    <Form.Item
      label="ОПИСАНИЕ"
      name="description"
      rules={[
        {
          required,
          message: "",
        },
      ]}
    >
      <Input.TextArea className="description-field" size="large" showCount maxLength={255} rows={6} />
    </Form.Item>
  );
}
