import { skipToken } from "@reduxjs/toolkit/query/react";

import { useGetItsmDelayReasonsQuery } from "core/app/api";
import { useApiSlice } from "core/shared/main/useApiSlice";
import { ApiSliceEnum } from "core/app/api/enums";

export function useItsmDelayReasons() {
  const stateSlice = useApiSlice();
  // pspa-kf-itsm-delay-reasons не существует на бекенде
  const slice = stateSlice === ApiSliceEnum.pspaKf ? ApiSliceEnum.pspa : stateSlice;

  return useGetItsmDelayReasonsQuery(slice || skipToken);
}
