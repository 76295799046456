import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";
import dayjs from "dayjs";
// eslint-disable-next-line import/no-extraneous-dependencies
import { saveAs } from "file-saver";
import { Button, Result, Spin } from "antd";
import {
  CameraFilled,
  CameraOutlined,
  CloseCircleOutlined,
  SaveOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Webcam from "react-webcam";
import { base64ToFile } from "file64";

import { EtoTaskAttachmentCategoryEnum, TaskAttachmentCategoryEnum } from "core/app/api/interfaces";
import { useUploadAttachmentMutation } from "core/app/api";
import { fileProcessing } from "core/shared/tools/fileProcessing";

import "./style.scss";
import PreviewImages from "./PreviewImages";
import { useMediaSize } from "./useMediaQuery";

type MediaCreationProps = {
  taskId: number;
  category: TaskAttachmentCategoryEnum | EtoTaskAttachmentCategoryEnum;
};

type TInfoModal = {
  status: "error" | "success" | undefined;
  msg: string;
  title: string;
  icon?: undefined | React.ReactNode;
};

function MediaCreation({ category, taskId }: MediaCreationProps) {
  const [uploadAttachmentRequest, info] = useUploadAttachmentMutation();
  const [blockCapture, setBlockCapture] = useState<boolean>(false);
  const [facing, setFacing] = useState<"user" | { exact: "environment" }>({ exact: "environment" });
  const [stateCamera, setStateCamera] = useState(false);
  const [infoModal, setInfoModal] = useState<null | TInfoModal>(null);
  const [images, setImages] = useState<{ src: string; id: string }[]>([]);

  const { width, height } = useMediaSize();
  const videoRef = useRef<Webcam>(null);

  const capture = useCallback(() => {
    if (!blockCapture) {
      const imageSrc = videoRef.current?.getScreenshot();
      setBlockCapture(true);
      if (imageSrc) {
        setImages((prevState) => [...prevState, { src: imageSrc, id: dayjs().format("DDMMYYYYHHmmss-SSS") }]);
        setInfoModal({
          status: undefined,
          msg: "",
          title: "Загрузка...",
          icon: <Spin size="large" />,
        });
        setTimeout(() => {
          setBlockCapture(false);
          setInfoModal(null);
        }, 1500);
      }
    }
  }, [videoRef, blockCapture]);

  const deleteImage = (id: string) => {
    const filterImag = images.filter((i) => i.id !== id);
    setImages(filterImag);
  };

  const closeCamera = () => {
    setInfoModal(null);
    setBlockCapture(false);
    setFacing({ exact: "environment" });
    setImages([]);
    setStateCamera(false);
  };

  const downloadImages = () => {
    images.forEach(({ src }) => {
      const fileName = `${dayjs().format("DD-MM-YYYY-HH-mm-ss-SSS")}.jpeg`;
      saveAs(src, fileName);
    });
    closeCamera();
  };

  const uploadImageServe = async (src: string) => {
    const fileImage = await base64ToFile(src, `${dayjs().format("DD-MM-YYYY-HH-mm-ss-SSS")}.jpeg`, {
      type: "image/jpeg",
    });
    const { isLessThenLimit, validFile, filePreload } = await fileProcessing(fileImage, false);
    if (isLessThenLimit && validFile) {
      const formData = new FormData();
      formData.append("category", category);
      formData.append("file", filePreload);
      uploadAttachmentRequest({
        taskId,
        body: formData,
      });
    }
  };

  const uploadFile = async () => {
    const loadingGallery = images.map((item) => uploadImageServe(item.src));
    Promise.all(loadingGallery).then();
    closeCamera();
  };

  const changeTypeCamera = () => {
    if (facing === "user") {
      setFacing({ exact: "environment" });
    } else {
      setFacing("user");
    }
  };

  const portal = document.getElementById("portal");

  useEffect(() => {
    if (info.status === "rejected") {
      setInfoModal({
        status: "error",
        title: "Проблема с соединением",
        msg: "Файлы сохранены в галлереи",
      });

      setTimeout(() => {
        downloadImages();
      }, 2000);
    }
  }, [info.status]);

  const container = (
    <div className="screenshot-container">
      <Webcam
        ref={videoRef}
        mirrored={facing === "user"}
        className={clsx({ mirrored: facing === "user" })}
        screenshotQuality={1}
        screenshotFormat="image/jpeg"
        forceScreenshotSourceSize
        videoConstraints={{
          facingMode: facing,
          width: { ideal: +width },
          height: { ideal: +height },
        }}
        onUserMediaError={(error) =>
          setInfoModal({
            status: "error",
            msg: String(error),
            title: "Ошибка устройства",
          })
        }
        onUserMedia={() => setInfoModal(null)}
      />
      <div className="screenshot-container-panel">
        <SyncOutlined style={{ fontSize: 45, color: "white" }} onClick={changeTypeCamera} />
        <Button
          shape="circle"
          size="large"
          onClick={downloadImages}
          disabled={Boolean(!images.length)}
          style={{ width: "60px", height: "60px", backgroundColor: "transparent", border: "none" }}
          icon={<SaveOutlined style={{ fontSize: 45, color: "white" }} />}
        />
        <div onClick={capture} className="screenshot-container-panel-screen">
          <CameraFilled style={{ fontSize: 45, color: "white" }} />
        </div>
        <Button
          style={{ width: "60px", height: "60px", backgroundColor: "transparent", border: "none" }}
          disabled={Boolean(!images.length)}
          onClick={uploadFile}
          shape="circle"
          size="large"
          icon={<UploadOutlined style={{ fontSize: 45, color: "white" }} />}
        />
      </div>
      <CloseCircleOutlined
        className="screenshot-container-close"
        style={{ fontSize: 45, color: "white" }}
        onClick={closeCamera}
      />
      {infoModal && (
        <Result
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(255,255,255,0.66)",
          }}
          status={infoModal.status}
          title={infoModal.title}
          subTitle={infoModal.msg}
          icon={infoModal?.icon}
        />
      )}
      {images.length && <PreviewImages deleteImage={deleteImage} images={images} />}
    </div>
  );

  return (
    <>
      <CameraOutlined onClick={() => setStateCamera(true)} style={{ fontSize: "20px" }} />
      {stateCamera && portal && createPortal(container, portal)}
    </>
  );
}

export default MediaCreation;
