import { Row, Col, Form, FormInstance } from "antd";
import { ReactElement } from "react";
import clsx from "clsx";

import { TaskTypesIdsEnum } from "core/app/api/interfaces";
import { useDisabled } from "core/shared/task/useDisabled";
import { useTask } from "core/shared/task/useTask";
import { useUnit } from "core/shared/unit/useUnit";
import { isPspaKfTask, isPspaTask, isSlpiTask } from "core/shared/task";

import { ElapsedTime } from "../../shared/ElapsedTime";
import { EmployeesSelect } from "../../shared/EmployeesSelect/FormControlled";
import UsedMaterialsTextArea from "../../shared/UsedMaterialsTextArea";

import ClosingCodeSelect from "./ClosingCodeSelect";
import DelayReasonSelect from "./DelayReasonSelect";
import ElementSelect from "./ElementSelect";
import MalfunctionSelect from "./MalfunctionSelect";
import MalfunctionReasonSelect from "./MalfunctionReasonSelect";
import ClosingNoteTextArea from "./ClosingNoteTextArea";
import SuspendedUntil from "./SuspendedUntil";
import NoteTextArea from "./NoteTextArea";
import ClosingDate from "./ClosingDate";
import ResponsibleGroupSelect from "./ResponsibleGroupSelect";
import { DepartmentSelect } from "./DepartmentSelect";
import { ObjectSelect } from "./ObjectSelect";
import { ComplexSystemSelect } from "./ComplexSystemSelect";
import { IncludeDetailedResultForCase } from "./IncludeDetailedResultForCase";

interface BodyProps {
  form: FormInstance;
}

function Body({ form }: BodyProps): ReactElement {
  const unit = useUnit();
  const { data: task } = useTask();
  const elementId = Form.useWatch("element_id");
  const malfunctionId = Form.useWatch("malfunction_id");
  const disabled = useDisabled();
  const required = unit?.isSLPI();

  if (!task) {
    return <div />;
  }

  return (
    <>
      <Row gutter={16} className={clsx("mt-2")}>
        <Col span={24}>
          <div>
            <ClosingCodeSelect disabled={disabled} form={form} />
          </div>
          <div>
            <ClosingNoteTextArea disabled={disabled} form={form} />
          </div>
          <div>
            <IncludeDetailedResultForCase disabled={disabled} form={form} />
          </div>
        </Col>
      </Row>
      {task.type === TaskTypesIdsEnum.RECOVERY && isPspaTask(task) && (
        <>
          <Row gutter={16} className={clsx("mb-2", "mt-2")}>
            <Col span={12}>
              <ElementSelect disabled={disabled} form={form} />
            </Col>
            <Col span={12}>
              <MalfunctionSelect form={form} disabled={disabled || !elementId} />
            </Col>
          </Row>
          <Row gutter={16} className={clsx("mb-2", "mt-2")}>
            <Col span={12}>
              <MalfunctionReasonSelect form={form} disabled={disabled || !malfunctionId} />
            </Col>
            <Col span={12}>
              {/* Временно блокируем селект для PSPAKF */}
              <ResponsibleGroupSelect disabled={disabled || isPspaKfTask(task)} form={form} />
            </Col>
          </Row>
        </>
      )}
      {task.type === TaskTypesIdsEnum.SERVICE_REQUEST && isPspaTask(task) && (
        <Row gutter={16} className={clsx("mb-2", "mt-2")}>
          <Col span={24}>
            {/* Временно блокируем селект для PSPAKF */}
            <ResponsibleGroupSelect disabled={disabled || isPspaKfTask(task)} form={form} />
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col span={12}>
          <DelayReasonSelect disabled={disabled} form={form} />
        </Col>
        <Col span={12}>
          <SuspendedUntil disabled={disabled} form={form} />
        </Col>
      </Row>
      {isSlpiTask(task) && (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <DepartmentSelect disabled={disabled} form={form} />
            </Col>
            <Col span={12}>
              <ObjectSelect disabled={disabled} form={form} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <ComplexSystemSelect disabled={disabled} form={form} />
            </Col>
            <Col span={12} />
          </Row>
        </>
      )}
      <Row gutter={16} className={clsx("mb-2", "mt-2")}>
        <Col span={12}>
          <div className="mb-8">
            <EmployeesSelect teamId={task.team_id} disabled={disabled} required={required} />
          </div>
          <Row gutter={8} align="bottom">
            <ElapsedTime useMultiplier={!unit?.isSLPI()} disabled={disabled} />
            <Col span={12}>
              <ClosingDate />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <div className="mb-3">
            <NoteTextArea form={form} disabled={disabled} />
          </div>
          <div>
            <UsedMaterialsTextArea disabled={disabled} />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Body;
