import { Col } from "antd";

import { IAttachment, TSendOtherPspaTaskBody, StateNamesEnum, TaskTypesIdsEnum } from "core/app/api/interfaces";
import { useUnitId } from "core/shared/unit/useUnitId";
import { useGetTaskStatesQuery } from "core/app/api";
import dayjs, { apiDateFormat, apiDateTimeFormat } from "core/shared/tools/dayjs";
import { IDictionaryItem } from "core/shared/interfaces";
import { useUnit } from "core/shared/unit/useUnit";
import { useCreatePspaTaskMutation } from "core/app/api/SGPS";
import { useApiSlice } from "core/shared/main/useApiSlice";
import { ApiSliceEnum } from "core/app/api/enums";

import EffortField from "../../../Fields/EffortField";
import DescriptionField from "../../../Fields/DescriptionField";
import AttachmentsField from "../../../Fields/AttachmentsField";
import ShiftSelector from "../../../Fields/ShiftSelector";
import WorkTypeField from "../../../Fields/WorkTypeField";
import { CreateTaskBaseForm } from "../../CreateTaskBaseForm";

import EquipmentTypeField from "./Fields/EquipmentTypeField";
import TransportField from "./Fields/TransportField";
import LocationField from "./Fields/LocationField";
import EquipmentField from "./Fields/EquipmentField";

export function Pspa() {
  const unit = useUnit();
  const apiSlice = useApiSlice();
  /** Тайпскрипт не реагирует на проверки через .includes */
  const isApiSliceValid = apiSlice && (apiSlice === ApiSliceEnum.pspaKf || apiSlice === ApiSliceEnum.pspa);
  const [unitId] = useUnitId();
  const [sendTask, sendTaskResult] = useCreatePspaTaskMutation();
  const { data: states } = useGetTaskStatesQuery("");

  const initialValues = {
    transportId: null,
    equipmentTypeId: null,
    equipmentId: null,
    locationId: null,
    description: "",
    attachments: [],
    effort_duration: "00:00:00",
    teamId: null,
    shiftNumber: 1 as 1 | 2 | 3,
    plannedDate: "",
    workTypeId: null,
    horizonId: null,
  };

  const createTask = async (values: typeof initialValues) => {
    if (!unitId || !states || !isApiSliceValid) {
      return Promise.reject();
    }
    const state = states.results.find((item: IDictionaryItem) => item.name === StateNamesEnum.New);

    const body: TSendOtherPspaTaskBody = {
      type: TaskTypesIdsEnum.OTHER,
      state_id: state?.id,
      unit_id: unitId,
      equipment_type_id: values.equipmentTypeId,
      request_description: values.description,
      location_id: values.locationId,
      effort_duration: values.effort_duration,
      work_type_id: values.workTypeId,
      transport_id: values.transportId,
      equipment_id: values.equipmentId,
      service_id: unit?.service.id,
      request_creation_datetime: dayjs().format(apiDateTimeFormat),
    };
    if (values.equipmentId) {
      body.is_mounted = false;
    }
    if (values.transportId) {
      body.is_mounted = true;
    }
    if (values.teamId) {
      body.team_id = values.teamId;
    }
    if (values.shiftNumber) {
      body.shift_number = values.shiftNumber;
    }
    if (values.plannedDate) {
      body.planned_date = dayjs(values.plannedDate).format(apiDateFormat);
    }
    if (values.attachments) {
      body.attachments = {
        files_by_task: values.attachments as IAttachment[],
        work_place: [],
        before_or_in_progress: [],
        after_completion: [],
      };
    }

    return sendTask({ pspaSlice: apiSlice, body }).unwrap();
  };

  return (
    <CreateTaskBaseForm
      initialValues={initialValues}
      createTaskFunction={createTask}
      createTaskIsLoading={sendTaskResult.isLoading}
      shouldCancelSubmit={!unitId || !states || !isApiSliceValid}
    >
      <Col span={12}>
        <LocationField />
      </Col>
      <Col span={12}>
        <EquipmentTypeField taskType={TaskTypesIdsEnum.OTHER} />
      </Col>
      <Col span={12}>
        <TransportField taskType={TaskTypesIdsEnum.OTHER} />
      </Col>
      <Col span={12}>
        <EquipmentField taskType={TaskTypesIdsEnum.OTHER} />
      </Col>
      <Col span={24}>
        <WorkTypeField />
      </Col>
      <Col span={12}>
        <EffortField name="effort_duration" required />
      </Col>
      <Col span={24}>
        <DescriptionField />
      </Col>
      <Col span={24}>
        <AttachmentsField />
      </Col>
      <Col className="mb-1 mt-1" span={24}>
        <ShiftSelector />
      </Col>
    </CreateTaskBaseForm>
  );
}
