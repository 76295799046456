import { ReactElement, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import { CanIVisitMenuItem } from "core/shared/components/CanIVisitMenuItem";
import { AppRoutes } from "core/shared/constants.routes";
import { FixedLayout } from "core/pages/Layouts/FixedLayout";
import { BaseLayout } from "core/pages/Layouts/BaseLayout";
import Dashboard from "core/pages/Dashboard";
import TaskSignatureJournal from "core/pages/TaskSignatureJournal";

const Teams = lazy(() => import("core/pages/Teams"));
const Maintenance = lazy(() => import("core/pages/Maintenance"));
const TasksForTeams = lazy(() => import("core/pages/TasksForTeams"));
const TasksForShift = lazy(() => import("core/pages/TasksForShift"));
const TasksForShiftTask = lazy(() => import("core/pages/TasksForShiftTask"));
const Tasks = lazy(() => import("core/pages/Tasks"));
const EmployeesSchedule = lazy(() => import("core/pages/EmployeesSchedule"));
const SLPI = lazy(() => import("core/pages/SLPI"));
const Equipments = lazy(() => import("core/pages/Equipments"));
const Help = lazy(() => import("core/pages/Help"));
const Rating = lazy(() => import("core/pages/Rating"));
const DailyReport = lazy(() => import("core/pages/DailyReport"));
const Oossa = lazy(() => import("core/pages/OOSSA"));
const SGPS = lazy(() => import("core/pages/SGPS"));
const PSS = lazy(() => import("core/pages/PSS"));

function LayoutRoutes(): ReactElement {
  return (
    <Routes>
      <Route
        path={AppRoutes.base}
        element={
          <Suspense>
            <BaseLayout>
              <Dashboard />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.teams}
        element={
          <Suspense>
            <BaseLayout>
              <CanIVisitMenuItem permissionKey="teams">
                <Teams />
              </CanIVisitMenuItem>
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.maintenance}
        element={
          <Suspense>
            <FixedLayout>
              <Maintenance />
            </FixedLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.tasksForTeams}
        element={
          <Suspense>
            <BaseLayout>
              <CanIVisitMenuItem permissionKey="tasks_for_teams" name="Распределение задач по бригадам">
                <TasksForTeams />
              </CanIVisitMenuItem>
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.taskForShift}
        element={
          <Suspense>
            <BaseLayout>
              <CanIVisitMenuItem permissionKey="tasks_for_shifts">
                <TasksForShift />
              </CanIVisitMenuItem>
            </BaseLayout>
          </Suspense>
        }
      >
        <Route
          path=":taskId"
          element={
            <Suspense>
              <BaseLayout>
                <TasksForShiftTask />
              </BaseLayout>
            </Suspense>
          }
        />
      </Route>
      <Route
        path={AppRoutes.tasks}
        element={
          <Suspense>
            <BaseLayout>
              <CanIVisitMenuItem permissionKey="tasks">
                <Tasks />
              </CanIVisitMenuItem>
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.employeesSchedule}
        element={
          <Suspense>
            <BaseLayout>
              <CanIVisitMenuItem permissionKey="employee_schedule">
                <EmployeesSchedule />
              </CanIVisitMenuItem>
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.slpi}
        element={
          <Suspense>
            <BaseLayout>
              <SLPI />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.equipments}
        element={
          <Suspense>
            <BaseLayout>
              <Equipments />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.help}
        element={
          <Suspense>
            <BaseLayout>
              <Help />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.rating}
        element={
          <Suspense>
            <BaseLayout fixed>
              <CanIVisitMenuItem permissionKey="team_rating" name="Рейтинг бригад">
                <Rating />
              </CanIVisitMenuItem>
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.dailyReport}
        element={
          <Suspense>
            <BaseLayout fixed>
              <CanIVisitMenuItem permissionKey="menu/reports/daily_report" name="Отчёт для проведения планерок">
                <DailyReport />
              </CanIVisitMenuItem>
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.oossa}
        element={
          <Suspense>
            <BaseLayout fixed>
              <Oossa />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.sgps}
        element={
          <Suspense>
            <BaseLayout>
              <SGPS />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.taskSignatureJournal}
        element={
          <Suspense>
            <BaseLayout fixed>
              <TaskSignatureJournal />
            </BaseLayout>
          </Suspense>
        }
      />
      <Route
        path={AppRoutes.pss}
        element={
          <Suspense>
            <BaseLayout fixed>
              <PSS />
            </BaseLayout>
          </Suspense>
        }
      />
    </Routes>
  );
}

export default LayoutRoutes;
