import { RoleEnum } from "core/shared/constants";
import { useHasUserOneOfSelectedRoles } from "core/shared/main/useHasUserOneOfSelectedRoles";

const permittedRoles = [
  RoleEnum.SLPI_WORKSHOP_LEAD,
  RoleEnum.PSPA_WORKSHOP_LEAD,
  RoleEnum.SHIFT_LEAD,
  RoleEnum.UNIT_LEAD,
];

/** Проверяет, имеет ли пользователь полномочия менять задачи PSPA */
export function useCanChangePspaTask() {
  return useHasUserOneOfSelectedRoles(permittedRoles);
}
