import { ReactElement } from "react";
import { Table } from "antd";

import { useDashboardShiftStatisticTasksByTeamsSlpi } from "core/shared/dashboard/api/useDashboardShiftStatisticTasksByTeamsSlpi";
import { IDashboardShiftStatisticTasksByTeamsSlpiTask } from "core/app/api/interfaces";
import { IGroup } from "core/shared/dashboard";

import ClosingNote from "../Fields/ClosingNote";
import Effort from "../Fields/Effort";
import ElapsedTime from "../Fields/ElapsedTime";
import InventoryNumber from "../Fields/InventoryNumber";
import { Object } from "../Fields/Object";
import { Comment } from "../Fields/Comment";
import RequestDescription from "../Fields/RequestDescription";

type TableRecord = IDashboardShiftStatisticTasksByTeamsSlpiTask | IGroup<IDashboardShiftStatisticTasksByTeamsSlpiTask>;

const columns = [
  {
    title: "ИНВ. НОМЕР",
    dataIndex: "inventory_number",
    render: (_: string, record: TableRecord) => <InventoryNumber record={record} />,
    width: 140,
  },
  {
    title: "ОБЪЕКТ",
    dataIndex: "object",
    render: (_: string, record: TableRecord) => <Object record={record} />,
  },
  {
    title: "ЗАКАЗЧИК",
    dataIndex: "customer",
  },
  {
    title: "ОПИСАНИЕ ЗАДАЧИ",
    dataIndex: "request_description",
    width: 300,
    render: (_: string, record: TableRecord) => <RequestDescription record={record} />,
  },
  {
    title: "СТАТУС",
    dataIndex: "status",
  },
  {
    title: "КОММЕНТАРИЙ",
    dataIndex: "request_comment",
    render: (_: string, record: TableRecord) => <Comment record={record} />,
  },
  {
    title: "ПРИМЕЧАНИЕ К РАЗРЕШЕНИЮ",
    dataIndex: "closing_note",
    render: (_: string, record: TableRecord) => <ClosingNote record={record} />,
  },
  {
    title: "ЗАГРУЗКА (ПЛАН)",
    dataIndex: "effort",
    render: (_: string, record: TableRecord) => <Effort record={record} />,
  },
  {
    title: "ЗАГРУЗКА (ФАКТ)",
    dataIndex: "elapsed_time",
    render: (_: string, record: TableRecord) => <ElapsedTime record={record} />,
  },
];

function ByTeamSlpiTable(): ReactElement {
  const dataSource = useDashboardShiftStatisticTasksByTeamsSlpi();

  return (
    <Table
      expandable={{
        defaultExpandAllRows: true,
        indentSize: 0,
      }}
      size="small"
      dataSource={dataSource}
      rowKey="id"
      columns={columns}
    />
  );
}

export { ByTeamSlpiTable };
