import { Row, Col, Divider, Button, Spin, Form, InputNumber } from "antd";
import { ReactElement, useState } from "react";

import { useUpdateTask } from "core/shared/task/useUpdateTask";
import Media from "core/widgets/TaskModals/shared/Media";
import Title from "core/widgets/TaskModals/shared/TaskTitle";
import { useSignatureStatus } from "core/shared/task/useSignatureStatus";
import { useDisabled } from "core/shared/task/useDisabled";
import { useTask } from "core/shared/task/useTask";
import ErrorMessage from "core/widgets/CreateTaskModal/ErrorMessage";
import { useUnit } from "core/shared/unit/useUnit";

import { SignatureForm } from "../../shared/SignatureForm";
import StateButtons from "../../shared/StateButtons/FormControlled";
import { EmployeesSelect } from "../../shared/EmployeesSelect/FormControlled";
import { ElapsedTime } from "../../shared/ElapsedTime";
import { ClosingDate } from "../../shared/ClosingDate";
import { Comment } from "../../shared/Comment";

import GeneralInfo from "./GeneralInfo";
import { MaintenanceExecutions } from "./MaintenanceExecutions";

import "../styles.scss";

interface ContentProps {
  taskId: number;
}

/** Карточка технического обслуживания (ТО) для стационарного оборудования ПСПА, СДГР, СЛПИ. */
function Content(props: ContentProps): ReactElement {
  const { taskId } = props;
  const { data: task } = useTask(taskId);
  const unit = useUnit();
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task);
  const [signatureStatus] = useSignatureStatus();
  const disabled = useDisabled();
  const [form] = Form.useForm();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleSave = (values: any) => {
    setShowErrorMessage(false);
    updateTaskRequest(values);
  };

  const handleValidationFailed = () => {
    setShowErrorMessage(true);
  };
  if (task) {
    return (
      <Form
        initialValues={task}
        scrollToFirstError
        onFinish={handleSave}
        onFinishFailed={handleValidationFailed}
        layout="vertical"
        form={form}
      >
        <Form.Item name="id" label="id" hidden>
          <InputNumber />
        </Form.Item>

        <Title icon task={task} />
        {!!signatureStatus && <SignatureForm task={task} />}
        {!signatureStatus && (
          <>
            {showErrorMessage && (
              <Col className="mb-3" span={24}>
                <ErrorMessage message="Невозможно сохранить задачу" />
              </Col>
            )}

            <GeneralInfo task={task} />
            <StateButtons task={task} hideClearButton onValidationFailed={handleValidationFailed} />
            <Divider />
            {!!task.maintenance_execution.length && (
              <>
                <MaintenanceExecutions task={task} />
                <Divider />
              </>
            )}
            <Row gutter={16}>
              <Col span={13}>
                <EmployeesSelect disabled={disabled} teamId={task.team_id} required />
                <Row gutter={8} align="bottom">
                  <ElapsedTime useMultiplier={!unit?.isSLPI()} disabled={disabled} />
                  <Col span={12}>
                    <ClosingDate value={task.planned_date} />
                  </Col>
                </Row>
              </Col>
              <Col span={11}>
                <Comment disabled={disabled} />
              </Col>
            </Row>
            <Media task={task} />
            <Divider />
            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  size="large"
                  className="w-100"
                  htmlType="submit"
                  loading={updateTaskResult.isLoading}
                  disabled={disabled}
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    );
  }
  return (
    <Row align="middle" justify="center" style={{ minHeight: "300px" }}>
      <Spin size="large" />
    </Row>
  );
}

export default Content;
