/* eslint-disable camelcase */
import { ReactElement, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "antd";

import icWorker from "core/images/electrician.svg";
import icLead from "core/images/lead.svg";
import Text from "core/lib/Text/Text";
import { AppRoutes } from "core/shared/constants.routes";
import { useGetDashboardShiftStatisticQuery } from "core/shared/dashboard/api/useGetDashboardShiftStatisticQuery";
import { displayDateFormat } from "core/shared/tools/dayjs";
import { ShiftPlanStatusesEnum } from "core/app/api/interfaces";
import DatePicker from "core/widgets/DatePicker";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import useUiPermissions from "core/shared/permissions/hooks/useUiPermissions";

import ShiftSelector from "./ShiftSelector";

function LeftCol(): ReactElement {
  const { setDate, date } = useContext(ShiftContext);
  const { CAN_SEE_SHIFT_FACT } = useUiPermissions("dashboard");
  const { data: dashboardShiftStatistic, isError, isSuccess } = useGetDashboardShiftStatisticQuery();

  const notEmptyComments = (dashboardShiftStatistic?.comment_by_shift_lead || []).filter(({ comment }) =>
    Boolean(comment)
  );
  return (
    <>
      <div className="mb-3">
        <NavLink to={AppRoutes.taskForShift}>
          <Text className="uppercase navlink" boldness="semibold">
            Текущая смена
          </Text>
        </NavLink>
      </div>
      <div className="mb-3">
        <DatePicker format={displayDateFormat} onChange={setDate} value={date} allowClear={false} />
      </div>
      <div className="mb-3">
        <ShiftSelector />
      </div>
      <Row className="mb-3" justify="center">
        <Col>
          <div>
            {isSuccess && dashboardShiftStatistic?.status === ShiftPlanStatusesEnum.CLOSED && (
              <Text
                style={{
                  backgroundColor: "#354A5F",
                  color: "#ffffff",
                  padding: "4px 10px",
                  borderRadius: "4px",
                  userSelect: "none",
                }}
                size="label2"
              >
                Смена завершена
              </Text>
            )}
            {isSuccess && dashboardShiftStatistic?.status === ShiftPlanStatusesEnum.APPROVED && (
              <Text
                style={{
                  backgroundColor: "#0091FF",
                  color: "#ffffff",
                  padding: "4px 10px",
                  borderRadius: "4px",
                  userSelect: "none",
                }}
                size="label2"
              >
                Смена в работе
              </Text>
            )}
            {(!dashboardShiftStatistic ||
              isError ||
              dashboardShiftStatistic?.status === ShiftPlanStatusesEnum.DISAPPROVED) && (
              <Text
                style={{
                  backgroundColor: "#77959B",
                  color: "#ffffff",
                  padding: "4px 10px",
                  borderRadius: "4px",
                  userSelect: "none",
                }}
                size="label2"
              >
                Смена не начата
              </Text>
            )}
          </div>
        </Col>
      </Row>
      {isSuccess && dashboardShiftStatistic && (
        <>
          <Row className="mb-2">
            <Col span={12}>
              <Row justify="center">
                <Col>
                  <Text size="label2">План. загр.</Text>
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <Text size="h5" boldness="black" style={{ lineHeight: 1 }}>
                    {dashboardShiftStatistic.planned_workload_percentage}%
                  </Text>
                </Col>
              </Row>
            </Col>
            {CAN_SEE_SHIFT_FACT && (
              <Col span={12}>
                <Row justify="center">
                  <Col>
                    <Text size="label2">Факт. загр.</Text>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col>
                    <Text size="h5" boldness="black" style={{ lineHeight: 1 }}>
                      {dashboardShiftStatistic.fact_workload_percentage}%
                    </Text>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={12}>
              <Row justify="center">
                <Col>
                  <img width="24px" height="24px" src={icLead} alt="" />
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <Text size="label2">Бригад</Text>
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <Text size="h5" boldness="black" style={{ lineHeight: 1 }}>
                    {dashboardShiftStatistic.teams_quantity}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row justify="center">
                <Col>
                  <img width="24px" height="24px" src={icWorker} alt="" />
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <Text size="label2">Чел.</Text>
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <Text size="h5" boldness="black" style={{ lineHeight: 1 }}>
                    {dashboardShiftStatistic.employees_quantity}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
          {dashboardShiftStatistic.status === ShiftPlanStatusesEnum.CLOSED && notEmptyComments.length > 0 && (
            <Row className="mt-2" gutter={[0, 16]}>
              <Col span={24}>
                <Text size="label2" boldness="semibold" className="uppercase">
                  КОММЕНТАРИИ
                </Text>
              </Col>
              {notEmptyComments.map(({ shift_lead, comment }) => (
                <Col className="shifts-comment" span={24} key={shift_lead}>
                  <Text size="label2" boldness="semibold" className="uppercase">
                    {shift_lead}:
                  </Text>

                  <br />
                  <Text tooltip={comment} size="label2">
                    {comment}
                  </Text>
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
    </>
  );
}

export default LeftCol;
