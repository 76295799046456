import { ReactElement } from "react";
import plugin from "dayjs/plugin/duration";

import dayjs, { formatHours } from "core/shared/tools/dayjs";
import { isGroup } from "core/shared/dashboard";
import { useUnit } from "core/shared/unit/useUnit";
import { formatHours as formatHoursHH } from "core/shared/main/tools/formatHours";

import { ICellProps } from "..";

function Effort({ record }: ICellProps): ReactElement {
  const unit = useUnit();
  let effort: plugin.Duration;
  if (isGroup(record)) {
    if (record.children?.length) {
      effort = record.children.reduce((duration, row) => duration.add(dayjs.duration(row.effort)), dayjs.duration(0));
      const hours = Math.floor(effort.asHours());
      const minutes = effort.minutes();
      const timeStr = `${hours}ч. ${minutes}м.`;
      if (unit?.isSLPI()) {
        return <div>{formatHoursHH(effort.asHours())}</div>;
      }
      return <div>{timeStr}</div>;
    }
    return <div />;
  }
  effort = dayjs.duration(record.effort);
  if (unit?.isSLPI()) {
    return <div>{formatHoursHH(effort.asHours())}</div>;
  }
  return <div>{formatHours(effort.asHours())}</div>;
}

export default Effort;
