import {
  IDashboardShiftStatisticTasksByTeamsSlpiTask,
  IDashboardShiftStatisticTasksByTeamsPspaTask,
  IDashboardShiftStatisticTasksByTeamsOossaTask,
} from "core/app/api/interfaces";

type TeamTask =
  | IDashboardShiftStatisticTasksByTeamsSlpiTask
  | IDashboardShiftStatisticTasksByTeamsPspaTask
  | IDashboardShiftStatisticTasksByTeamsOossaTask;

export interface IGroup<T extends TeamTask> {
  name: string;
  id: string;
  type: string;
  children?: T[];
}

export const isGroup = (record: IGroup<TeamTask> | TeamTask): record is IGroup<TeamTask> => {
  if (record.type === "group") {
    return true;
  }
  return false;
};
