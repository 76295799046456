import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { MaintenanceTypeEnum } from "core/app/api/interfaces";

export enum PlanTypeEnum {
  MONTHLY = "monthly",
  ANNUAL = "annual",
}

type TSGPSState = {
  typeTabs: MaintenanceTypeEnum;
  activeMonth: number;
  typePlan: PlanTypeEnum;
  equipmentTypeSelect: number;
  typeSdoSelect: number;
  autoCycle: boolean;
};

const initialState: TSGPSState = {
  typeTabs: MaintenanceTypeEnum.STATIONARY,
  typePlan: PlanTypeEnum.ANNUAL,
  activeMonth: 1,
  equipmentTypeSelect: -1,
  typeSdoSelect: -1,
  autoCycle: true,
};

export const SGPSSlice = createSlice({
  name: "sgps",
  initialState,
  reducers: {
    setTypedTab(state, action: PayloadAction<MaintenanceTypeEnum>) {
      state.typeTabs = action.payload;
    },

    setActiveMonth(state, action: PayloadAction<number>) {
      state.activeMonth = action.payload;
    },

    setTypePlan(state, action) {
      state.typePlan = action.payload;
    },

    setEquipmentTypSelect(state, action) {
      state.equipmentTypeSelect = action.payload;
    },

    setTypeSdoSelect(state, action: PayloadAction<number>) {
      state.typeSdoSelect = action.payload;
    },

    setAutoCycle(state, action: PayloadAction<boolean>) {
      state.autoCycle = action.payload;
    },

    resetFilters(state) {
      state.equipmentTypeSelect = -1;
      state.typeSdoSelect = -1;
    },
  },
});

export const {
  resetFilters,
  setAutoCycle,
  setTypeSdoSelect,
  setEquipmentTypSelect,
  setTypedTab,
  setActiveMonth,
  setTypePlan,
} = SGPSSlice.actions;
