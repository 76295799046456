import { IStringifyOptions } from "qs";

import { RoleEnum, ShiftSlugsEnum } from "core/shared/constants";
import { IDictionaryItem } from "core/shared/interfaces";
import { TUiPermissionKey } from "core/shared/permissions/interfaces";
import { IUnit, UnitServiceCodesEnum, UnitServicesEnum } from "core/shared/unit/types";
import { TaskApiSlice } from "core/shared/task/useTask";

import { IEquipmentSystemSummaryMonth } from "../store/maintenance-slice";

import { ILsoTask } from "./LSO/interfaces";
import { ISgpsTask } from "./SGPS/interface";
import { TWorkType } from "./types";

export interface ILocation extends IDictionaryItem {
  unit: number;
}

export interface IPspaWorkType extends IDictionaryItem {
  description: string;
}

/** Состояние задания (карточки на выполнение работ). */
export enum StateNamesEnum {
  New = "Новая",
  InWork = "В работе",
  OnPause = "На паузе",
  Closed = "Закрыта",
}

export enum EmployeeScheduleDayTypeEnum {
  WORKING_DAY = 1,
  NON_WORKING_DAY = 2,
  VACATION = 3,
  SICK_LEAVE = 4,
  TIME_OFF_DAY = 5,
  BUSINESS_TRIP = 6,
  MEDICAL_EXAMINATION = 7,
}

export interface IClosingCode extends IDictionaryItem {
  type: string;
  is_deactivated: boolean;
}

export interface IDelayReason extends IDictionaryItem {
  is_deactivated: boolean;
  is_basic: boolean;
  can_be_transfered_to_working_state: boolean;
  is_suspended_until_required: boolean;
}

export interface IUpdateTaskCheckListPayload {
  taskId: number;
  maintenanceExecution: number;
  checklist: number;
  value: number;
}

export interface IUpdateTaskStatePayload {
  taskId: number | string;
  stateId: number;
}

export interface IUnitYearMonth {
  unit_id: number;
  year: number;
  month: number;
}

export interface ICopyMonthPlanPayload {
  unit_id: number;
  source_year: number;
  source_month: number;
  destination_year: number;
  destination_month: number;
  maintenance_type: string;
}

export interface IRequiredTaskCount {
  month: number;
  maintenance_quantity: number;
}

export interface IHorizon extends IDictionaryItem {
  unit: number | null;
  unit_id: number | null;
}

export interface ICredentials {
  username: string;
  password: string;
}

export interface ITasksStatisticsResponse {
  employees_count: number;
  maintenance_count: number;
  recovery_count: number;
  service_request: number;
  percentage: number;
}

export interface ITasksStatisticsByShift {
  employees_count: number;
  maintenance_count: number;
  recovery_count: number;
  preventive_count: number;
  service_request: number;
  percentage: number;
}

export interface ITasksStatisticsByTeam {
  employees_count: number;
  maintenance_count: number;
  recovery_count: number;
  preventive_count: number;
  service_request: number;
  percentage: number;
}

export interface IUnitShift {
  unit: {
    id: number;
    name: string;
    efficiency: number;
  };
  shift_number: 1 | 2 | 3;
}

export interface IResponseBody<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface IPermissionResponseBody {
  permission: string;
  granted: boolean;
}

export enum TransportStatusEnum {
  IN_WORK = "IN_WORK",
  IN_WORK_WITH_MALFUNCTION = "IN_WORK_WITH_MALFUNCTION",
  NOT_IN_WORK = "NOT_IN_WORK",
}

export interface ITransport {
  id: number;
  name: string;
  description?: string;
  manufacturer?: string;
  model?: string;
  status: TransportStatusEnum;
  not_working_since?: string;
  location?: IDictionaryItem;
  location_id?: number;
  engine_hours?: number;
  serial_number?: string;
}

export interface IEquipmentType {
  id: number;
  name: string;
  maintenance_quantity: number;
  is_mounted: boolean;
  system: number;
  system_name: string;
}

export interface IEquipment {
  id: number;
  name: string;
  location_id: number | null;
  location: ILocation | null;
  transport: null | ITransport;
  equipment_type_id: null | number;
  system_id: null | number;
  has_malfunction: boolean;
  equipment_model?: string;
}

export interface IEquipmentLastProblem {
  severity: number;
  time: string;
  problem: string;
}

export interface IEmployee {
  id: number;
  unit_id: number;
  personnel_number: string;
  last_name: string;
  first_name: string;
  middle_name: string;
  full_name: string;
  position: string;
  working_mode: string;
  is_driver?: boolean;
  is_newbie?: boolean;
  is_foreman: boolean;
}

interface ITotals {
  hours: number;
  shifts: number;
}

export interface IScheduledDay {
  scheduled_day_id: number;
  scheduled_day: {
    date: string;
    shift_number: number;
    schedule_day_type_id: number;
    schedule_day_type: {
      id: number;
      code: EmployeeScheduleDayTypeEnum;
      description: string;
    };
  };
}

export interface IGroupEmployee {
  group_employee_id: number;
  group_id: number;
  totals: ITotals;
  employee: IEmployee;
  monthScheduleId: number;
  scheduled_days: IScheduledDay[];
}

export interface IWorkingRhythm {
  id: number;
  working_days_count: number;
  non_working_days_count: number;
  working_hours: number;
  description: string;
}

export interface IGroup {
  group_id: number;
  totals: ITotals;
  group: {
    id: number;
    date_from: string;
    initial_shift_number: 1 | 2 | 3;
    working_rhythm_id: number;
    working_rhythm?: IWorkingRhythm;
    name: string;
    locations: {
      group_location_id: number;
      group_location: {
        id: number;
        location: {
          id: number;
          name: string;
        };
      };
    }[];
    scheduled_days: IScheduledDay[];
  };
  employees: IGroupEmployee[];
}

export interface IMonthSchedule {
  month_shedule_id: number;
  month_schedule: {
    id: number;
    unit_id: number;
    unit: IUnit;
    year: number;
    month: number;
    created_at: string;
    updated_at: string;
    created_by: number;
    is_approved: boolean;
    approved_by: number;
    approved_at: string;
    groups: IGroup[];
  };
}

export enum TaskSnapMomentEnum {
  APPROVAL = "APPROVAL",
  CLOSURE = "CLOSURE",
  ADDING_TO_THE_TEAM = "ADDING_TO_THE_TEAM",
  REMOVE_FROM_TEAM = "REMOVE_FROM_TEAM",
  TEAM_DISBAND = "TEAM_DISBAND",
}

export interface ITeam {
  id: number | null;
  team_number: number | null;
  unit_id: number | null;
  team_lead_id?: number | null;
  team_lead_short_name: string | null;
  date: string;
  shift_number: 1 | 2 | 3;
  employees: IEmployee[];
  unit_department_id?: number;
  moment: TaskSnapMomentEnum | null;
  disbanded: boolean;
}

/** Типы заявок на обслуживание (перечисление). */
export enum TaskTypesIdsEnum {
  /** Не используется? ("Из планирования"). */
  PREVENTIVE = "PREVENTIVE",
  /** Техническое обслуживание (ТО). */
  MAINTENANCE = "MAINTENANCE",
  /** Аварийно-восстановительная работа (АВР), INC-заявка из ITSM Сириус. */
  RECOVERY = "RECOVERY",
  /** Прочее (задача на дополнительные работы). */
  OTHER = "OTHER",
  /** Запрос на обслуживание (ЗНО), RITM-заявка из ITSM Сириус). */
  SERVICE_REQUEST = "SERVICE_REQUEST",
  /** Ежедневное техническое обслуживание "Хозяек" */
  ETO = "ETO",
}

export enum UrgencyEnum {
  Low = 0,
  Medium = 1,
  High = 2,
  Critical = 3,
}

/** Группировка (подзаголовок) для работ чек-листа карточки ТО. */
export enum CheckItemGroupsEnum {
  /** Раздел "Работоспособность". */
  Performance = 1,
  /** Раздел "Работа". */
  Work = 2,
}

/** Одна строка в карточке выполнения работ по ТО.
 * Это относящийся (привязанный) к одной закладке либо пункт чек-листа, либо измеренное значение. */
export interface IMaintenanceExecutionWork {
  id: number;
  /** Для группировки в чек-листе по разделам. Но это поле вычисляемое (только на фронте). */
  group_number?: number;
  /** Для сортировки в чек-листе внутри раздела. На бэке оно (зачем-то) является числом, но в формате строки с дополненными нулями слева. */
  order_number?: string;
  /** Комментарий при выполнении. */
  comment?: string | null;
}

/** Пункт чек-листа в карточке выполнения работ по ТО. */
export interface IMaintenanceExecutionCheckListItem extends IMaintenanceExecutionWork {
  check: {
    id: number;
    title: string;
    description: string;
    /** Для группировки в чек-листе по разделам. */
    group_number: number;
  };
  value: number;
}

/** Измеренное значение в карточке выполнения работ по ТО. */
export interface IMaintenanceExecutionValuesItem extends IMaintenanceExecutionWork {
  field: {
    id: number;
    title: string;
    description: string;
    /** Для группировки в чек-листе по разделам. */
    group_number: number;
    choices: string[];
    is_required: boolean;
  };
  value: string;
}

/** Данные одной закладки (по типу оборудования) в карточке выполнения работ по ТО. Это один чек-лист + измеренные значения. */
export interface IMaintenanceExecution {
  /** Тип оборудования, соответствует закладке в карточке ТО на тех. узел, в котором много оборудования. */
  equipment_type: {
    id: number;
    name: string;
    maintenance_quantity: number;
    is_mounted: boolean;
    system: null;
    system_name: string | null;
  };
  id: number;
  is_completed: boolean;
  is_broken: boolean;
  comment: null | string;
  used_materials: null;
  checklist: IMaintenanceExecutionCheckListItem[];
  values: IMaintenanceExecutionValuesItem[];
}

export enum TaskAttachmentCategoryEnum {
  WorkPlace = "work_place",
  Before = "before_or_in_progress",
  After = "after_completion",
  FilesByTask = "files_by_task",
}

export enum EtoTaskAttachmentCategoryEnum {
  ShiftStart = "shift_start",
  ShiftEnd = "shift_end",
  Other = "other",
}

export interface IAttachment {
  id: number;
  object_name: string;
  original_name: string;
  content_type: string;
  created_at: string;
  category: TaskAttachmentCategoryEnum;
  should_be_sent?: boolean;
}

export interface IAddress extends IDictionaryItem {
  unit: number;
}

export type TDepartment = IAddress;

export interface ISlpiMonthPlan extends IDictionaryItem {
  id: number;
  name: "P-1" | "P-2" | "P-3";
  scheduled_days: number[];
  completed_days: number[];
  recovery_days: number[];
  repaired_days: number[];
  work_in_progress_days: number[];
  year: number;
  month: number;
  hours_norm: number;
  employees_quantity: number;
  is_approved: boolean;
  approved_at: null | string;
  created_at: string;
  updated_at: string;
  approved_by: null | number;
  tasks: Record<number, number>;
}

export interface ISlpiObjectSystemSet {
  id: number;
  inventory_number: null | string;
  project_number: null | string;
  systems: IDictionaryItem[] | null;
  month_plans: ISlpiMonthPlan[];
}

export interface ISlpiObject extends IDictionaryItem {
  system_set: ISlpiObjectSystemSet[] | null;
  inventory_number: string | null;
  project_number: string | null;
  type: "GROUND";
  unit: number | null;
  customer: number | null;
}

export interface ISLPISystemComplexLight {
  id: number;
  inventory_number: string | null;
  project_number: string | null;
  systems: IDictionaryItem[] | null;
}

export type TSLPISystemComplex = ISLPISystemComplexLight;

interface IRequestCategory {
  id: string;
  name: string;
  itsm_category_group: string;
  is_deactivated: boolean;
  required_customer_approval: boolean;
}

/** Объект ООССА. */
export interface IOossaObject extends IDictionaryItem {
  customer_department?: number;
  location?: number;
}

/** Система ООССА - не используется? */
export interface IOossaSystem extends IDictionaryItem {
  system_name: string | null;
  full_name: string | null;
  system: number | null;
}

/** Система. Также используется и в ООССА. */
export interface ISystemInterface extends IDictionaryItem {
  code_enum: string | null;
}

/** Тип средства измерения в ООССА (тип СИ). */
export interface IOossaEquipmentType extends IDictionaryItem {
  /** ХЗ. Поле есть в oossa-equipments, в oossa-tasks (в разделе maintenance_execution). */
  maintenance_quantity: number;
  /** Подвесное? Поле есть в oossa-equipments, в oossa-tasks (в разделе maintenance_execution). */
  is_mounted: boolean;
  /** Ид системы. Поле есть в oossa-equipments, в oossa-tasks (в разделе maintenance_execution). */
  system: number;
  /** Наименование системы. Поле есть только в oossa-tasks (в разделе maintenance_execution). */
  system_name?: string | null;
}

/** Оборудование ООССА. */
export interface IOossaEquipment extends IDictionaryItem {
  /** Устарело? */
  height: string | null;
  /** Производитель. */
  manufacturer: IDictionaryItem | null;
  /** Тип СИ. */
  equipment_type: IOossaEquipmentType | null;
  /** Серийный номер. */
  serial_number: string | null;
  /** Модель/марка. */
  model: string | null;
  /** Дата поверки. */
  verification_date: string | null;
  /** Дата производства. */
  manufactured_date: string | null;
  /** Условный диаметр ("ДУ"). */
  nominal_diameter: string | null;
  outdoor: boolean;
  accuracy_class: string | null;
  transformation_ratio: string | null;
  power_phases: string | null;
  /** Тип связи. */
  connection_type: string | null;
  /** ИД технического узла (ТУ). */
  tech_node: number;
}

/** Карточка технического обслуживания (ТО) для ООССА. */
// export interface OossaTask {
//   /** УСТАРЕЛО. Не передается с бэка. */
//   oossa_object?: OossaObject;
//   oossa_object_id?: number;
//   /** УСТАРЕЛО. Не передается с бэка. */
//   oossa_system?: OossaSystem;
//   /** УСТАРЕЛО. Не передается с бэка. */
//   oossa_system_id?: number;
//   /** Технический узел ООССА. */
//   oossa_tech_node_id?: number;
//   /** Оборудование (устарело? бэк не возвращает). */
//   oossa_equipment?: OossaEquipment;
//   /** Ид оборудования (только для СМЗиС). */
//   oossa_equipment_id?: number;
// }

// export interface SlpiTask {
//   customer_department?: DictionaryItem;
//   customer_department_id?: number | null;
//   effort_by_system_complex?: number | null;
// }

export interface IService {
  id: number;
  name: UnitServicesEnum;
  code: UnitServiceCodesEnum;
}

export enum TaskDiscriminatorEnum {
  PSPA_TASK = "PSPA_TASK",
  SLPI_TASK = "SLPI_TASK",
  OOSSA_TASK = "OOSSA_TASK",
  ETO_PSPA_TASK = "ETO_PSPA_TASK",
  PSPA_KF_TASK = "PSPA_KF_TASK",
  LSO_TASK = "LSO_TASK",
}

interface IEtoTransportStatus {
  comment: string;
  end_date: null | string;
  planned_date: null | string;
  start_date: null | string;
  status_type: TransportStatusEnum;
  status_type_id: number;
  status_name: string;
}

interface IEtoTransportMaintenancePlanner {
  remaining_engine_hours: string;
  next_maintenance_engine_hours: number;
}

interface IEtoTransport {
  engine_hours: number;
  id: number;
  kio: null | number;
  ktg: null | number;
  name: string;
  next_maintenance_date: null;
  maintenance_planner: IEtoTransportMaintenancePlanner[];
  serial_number: string;
  status: IEtoTransportStatus;
  unit_id: number;
}

export interface IBaseTask {
  id: number;
  number: string;
  /** Услуга - соответствует структурному подразделению НН Спутник, которое предоставляет услуги ТОиР: ПСПА, СЛПИ, ООССА... */
  service_id: number | null;
  service: IService | null;
  team_id: number | null;
  team: ITeam | null;
  /** Заказчик. */
  customer?: IDictionaryItem;
  customer_id?: number | null;
  shift_number: 1 | 2 | 3 | null;
  /** Фактические трудозатраты (чел*час) в формате времени ("ДД ЧЧ:ММ:СС" или "ЧЧ:ММ:СС"). */
  accumulator_elapsed_time: string | null;
  /** Исполнитель (ид) по карточке ТО (кто закрыл карточку). */
  maintenance_executor_id: null | number;
  unit_id: number | null;
  type: TaskTypesIdsEnum;
  discriminator: TaskDiscriminatorEnum;
  unit: IUnit;
  /** Исполнитель по карточке ТО (кто закрыл карточку). */
  maintenance_executor?: null | number;
  elapsed_time_multiplier: number | null;
  /** Плановые трудозатраты (чел*час). */
  effort: number | null;
  effort_duration?: string | null;
  state_id: number | null;
  state: IDictionaryItem | null;
  created_at?: string;
  updated_at: string | undefined;
  /** Плановая дата выполнения. */
  planned_date: string | null;
  closing_date: string | null;
  closing_note: string | null;
  elapsed_time: string | null;
  moment: TaskSnapMomentEnum | null;
  note: string | null;
  comment: string;
  //  Для разных типов задач могут быть разные ключи
  attachments: {
    work_place: IAttachment[];
    before_or_in_progress: IAttachment[];
    after_completion: IAttachment[];
    files_by_task: IAttachment[];
  };
}

export enum EtoTaskStepNameEnum {
  PRE_SHIFT_CHECK = "PRE_SHIFT_CHECK",
  PERFORMING_ETO = "PERFORMING_ETO",
  POST_SHIFT_CHECK = "POST_SHIFT_CHECK",
}

export interface IAssistanceCarCheck {
  uuid: string;
  check: string;
  value: boolean | null;
  comment: string;
}

export interface IAssistanceCarCheckList {
  uuid: string;
  group: string;
  checks: IAssistanceCarCheck[];
}

export interface IEtoTaskStep {
  checklist_json: IAssistanceCarCheckList[];
  driver_signature_id: null;
  editable: boolean;
  id: number;
  mechanic_signature_id: null;
  step_name: EtoTaskStepNameEnum;
}

export interface IEtoTask extends IBaseTask {
  engine_oil_added: number;
  transmission_oil_added: number;
  hydraulic_tank_water_added: number;
  battery_water_added: number;
  fuel_added: number;
  transport_status_from_driver: TransportStatusEnum;
  steps: IEtoTaskStep[];
  current_step_id: number;
  transport_id: number;
  transport: ITransport;
}

export interface INoPhotoReason extends IDictionaryItem {
  is_comment_required: boolean;
}

export interface IAdditionalTasksProps {
  request_work_time_usage_percent?: number;
  description: string;
  /** Навесное оборудование? */
  request_status: string | null;
  request_priority: 0 | 1 | 2 | 3 | null;
  request_description: string;
  request_creation_datetime: string | null;
  request_equipment: string | null;
  repair_area_id: null | number;
  repair_area: IDictionaryItem | null;
  creatio_link: string;
  work_time_usage_hours: number | null;
  elapsed_time: string | null;
  request_has_backlog: null | boolean;
  request_id: string | null;
  request_comment: string | null;
  request_initiator?: string | null;
  /** Локация. */
  location: IDictionaryItem | null;
  /** Локация (ид). */
  location_id: number | null;
  /** Фактические трудозатраты (чел*час) в формате времени ("ДД ЧЧ:ММ:СС" или "ЧЧ:ММ:СС"). */
  accumulator_elapsed_time: string | null;
  /** Чек-лист ТО. */
  maintenance_execution: IMaintenanceExecution[];
  /** Исполнитель по карточке ТО (кто закрыл карточку). */
  maintenance_executor?: null | number;
  /** Исполнитель (ид) по карточке ТО (кто закрыл карточку). */
  maintenance_executor_id: null | number;
  initiator_id: null | number;
  initiator: null | IEmployee;
  used_materials: string | null;
  closing_code: IClosingCode | null;
  closing_code_id: string | null;
  delay_reason: IDelayReason | null;
  delay_reason_id: string | null;
  itsm_equipment: IDictionaryItem | null;
  element: IDictionaryItem | null;
  element_id: string | null;
  malfunction: IDictionaryItem | null;
  malfunction_id: string | null;
  malfunction_reason: IDictionaryItem | null;
  malfunction_reason_id: string | null;
  suspended_until: string | null;
  address: IAddress;
  address_id: number;
  responsible_group_id: null | number;
  responsible_group: IDictionaryItem | null;
  weight?: number;
  request_category: IRequestCategory | null;
  include_detailed_result_for_case?: boolean;
  no_photo_reason: null | string;
  work_type_id: null | number;
  work_type: TWorkType | null;
  case_service_offering_id: string | null;
  no_photo_reason_type_id: number;
  deadline: string | null;
  request_mine_name: string | null;
  no_photo_reason_type: INoPhotoReason;
}

export interface IOossaTask extends IBaseTask, IAdditionalTasksProps {
  oossa_object: IOossaObject;
  oossa_object_id?: number;
  oossa_tech_node: number;
  oossa_tech_node_id?: number;
  oossa_equipment?: IOossaEquipment;
  oossa_equipment_id?: number;
  system: IDictionaryItem | null;
  system_id: number | null;
  customer_department?: TDepartment;
  customer_department_id?: number | null;

  /** УСТАРЕЛО. Не передается с бэка. */
  oossa_system?: IOossaSystem;
  /** УСТАРЕЛО. Не передается с бэка. */
  oossa_system_id?: number;
}

export interface IPspaTask extends IBaseTask, IAdditionalTasksProps {
  system_id: number | null;
  system: IDictionaryItem | null;
  /** Оборудование (ид), если карточка ТО на одну единицу обрудования. */
  equipment_id: number | null;
  equipment: IDictionaryItem | null;
  /** Тип оборудования (ид), если карточка ТО на одну единицу обрудования. */
  equipment_type_id: number | null;
  /** Тип оборудования, если карточка ТО на одну единицу обрудования. */
  equipment_type: IDictionaryItem | null;
  horizon_id: number | null;
  horizon: IHorizon | null;
  transport_id: number | null;
  transport: IEtoTransport | null;
  /** Навесное оборудование? */
  is_mounted: boolean | null;
}

export interface ISlpiTask extends IBaseTask, IAdditionalTasksProps {
  customer_department?: IDictionaryItem;
  customer_department_id?: number | null;
  object?: ISlpiObject;
  object_id?: number | null;
  system_complex: TSLPISystemComplex | undefined;
  system_complex_id: null | number;
  effort_by_system_complex?: number | null;
  slpi_maintenance_name?: null | string;
}

/** Карточка технического обслуживания (ТО). */
export interface ITask extends IOossaTask, ISlpiTask, ILsoTask, ISgpsTask {
  id: number;
  discriminator: TaskDiscriminatorEnum;
  number: string;
  description: string;
  type: TaskTypesIdsEnum;
  /** Локация. */
  location: IDictionaryItem | null;
  /** Локация (ид). */
  location_id: number | null;
  system_id: number | null;
  /** Система оборудования. */
  system: IDictionaryItem | null;
  /** Тип оборудования (ид), если карточка ТО на одну единицу обрудования. */
  equipment_type_id: number | null;
  /** Тип оборудования, если карточка ТО на одну единицу обрудования. */
  equipment_type: IDictionaryItem | null;
  /** Участок. */
  unit: IUnit;
  unit_id: number | null;
  /** Оборудование (ид), если карточка ТО на одну единицу обрудования. */
  equipment_id: number | null;
  /** Оборудование, если карточка ТО на одну единицу обрудования. */
  equipment: IDictionaryItem | null;
  /** Есть ли проблема, по данным Zabbix? */
  equipment_has_problem: boolean | null;
  /** Текстовое сообщение Zabbix. */
  equipment_last_problem: string | null;
  horizon_id: number | null;
  horizon: IHorizon | null;
  transport_id: number | null;
  transport: ITransport | null;
  /** Плановая дата выполнения. */
  planned_date: string | null;
  request_id: string | null;
  request_comment: string | null;
  request_has_backlog: null | boolean;
  /** Плановые трудозатраты (чел*час). */
  effort: number | null;
  effort_duration?: string | null;
  urgency: UrgencyEnum;
  state_id: number | null;
  state: IDictionaryItem | null;
  /** Услуга - соответствует структурному подразделению НН Спутник, которое предоставляет услуги ТОиР: ПСПА, СЛПИ, ООССА... */
  service: IService | null;
  service_id: number | null;
  deadline: string | null;
  team_id: number | null;
  team: ITeam | null;
  /** Навесное оборудование? */
  is_mounted: boolean | null;
  request_status: string | null;
  request_priority: 0 | 1 | 2 | 3 | null;
  request_description: string;
  request_creation_datetime: string | null;
  request_equipment: string | null;
  shift_number: 1 | 2 | 3 | null;
  closing_date: string | null;
  comment: string;
  repair_area_id: null | number;
  repair_area: IDictionaryItem | null;
  creatio_link: string;
  work_time_usage_hours: number | null;
  elapsed_time: string | null;
  /** Фактические трудозатраты (чел*час) в формате времени ("ДД ЧЧ:ММ:СС" или "ЧЧ:ММ:СС"). */
  accumulator_elapsed_time: string | null;
  /** Чек-лист ТО. */
  maintenance_execution: IMaintenanceExecution[];
  /** Исполнитель по карточке ТО (кто закрыл карточку). */
  maintenance_executor?: null | number;
  /** Исполнитель (ид) по карточке ТО (кто закрыл карточку). */
  maintenance_executor_id: null | number;
  initiator_id: null | number;
  initiator: null | IEmployee;
  used_materials: string | null;
  attachments: {
    work_place: IAttachment[];
    before_or_in_progress: IAttachment[];
    after_completion: IAttachment[];
    files_by_task: IAttachment[];
  };
  closing_code: IClosingCode | null;
  closing_code_id: string | null;
  delay_reason: IDelayReason | null;
  delay_reason_id: string | null;
  itsm_equipment: IDictionaryItem | null;
  element: IDictionaryItem | null;
  element_id: string | null;
  moment: TaskSnapMomentEnum | null;
  malfunction: IDictionaryItem | null;
  malfunction_id: string | null;
  malfunction_reason: IDictionaryItem | null;
  malfunction_reason_id: string | null;
  closing_note: string | null;
  suspended_until: string | null;
  note: string | null;
  address: IAddress;
  address_id: number;
  created_at: string | undefined;
  updated_at: string | undefined;
  responsible_group_id: null | number;
  responsible_group: IDictionaryItem | null;
  system_complex: TSLPISystemComplex | undefined;
  system_complex_id: null | number;
  slpi_maintenance_name?: null | string;
  object?: ISlpiObject;
  object_id?: number | null;
  customer_department?: TDepartment;

  /** Заказчик. */
  customer?: IDictionaryItem;
  customer_id?: number | null;
  request_initiator?: string | null;
  include_detailed_result_for_case?: boolean;
  no_photo_reason: null | string;
  work_type_id: null | number;
  work_type: TWorkType | null;
  request_category: IRequestCategory | null;
  case_service_offering_id: string | null;
  no_photo_reason_type_id: number;
}

export interface IMonthPlanEquipmentType {
  id: number;
  attached_recovery_id: number | null;
  attached_recovery_task_closed: boolean | null;
  name: string;
  is_mounted: boolean;
  system: number;
  system_name: string;
  is_completed: boolean;
  is_broken: boolean;
  has_comment: boolean;
  is_repaired: boolean;
  recovery_state: "open" | "closed" | null;
  last_open_recovery_task_description: string | null;
  last_recovery_task_id: number | null;
}

export interface IMonthPlan {
  id: number;
  year_plan_id: number;
  month: number;
  enabled: boolean;
  equipment_id: number | null;
  equipment: IEquipment | null;
  scheduled_maintenance_quantity: number;
  scheduled_days: number[];
  completed_maintenance_quantity: number;
  completed_maintenance_by_month?: number;
  required_maintenance_quantity?: number;
  completed_days: number[];
  recovery_started_tasks: {
    [key: number]: number[];
  };
  recovery_closed_tasks: {
    [key: number]: number[];
  };
  recovery_days: number[];
  repaired_days: number[];
  transport: ITransport | null;
  transport_id: number | null;
  work_in_progress_days: number[];
  equipment_types: IMonthPlanEquipmentType[];
  last_closed_task:
    | any
    | {
        id: number;
        executor: {
          unit_id: number | null;
          personnel_number: string;
          last_name: string;
          first_name: string;
          middle_name: string;
          position: string;
          is_foreman: boolean;
          working_mode: string;
        };
        comment: string;
      };
}

export interface IAggregation {
  verbose_name: string;
  amount: number;
  key: number;
}

export interface IDashboardOpenedTasksStatistic {
  types: ["RECOVERY"];
  amount: number;
  state_aggregation: IAggregation[];
  priority_aggregation: IAggregation[];
  work_time_usage_percent_aggregation: IAggregation[];
}

export type TPlanFactStatistic = [
  {
    is_mounted: boolean;
    amount: number;
    repeated_amount?: number;
    out_of_plan?: number;
  },
  {
    is_mounted: boolean;
    amount: number;
    repeated_amount?: number;
    out_of_plan?: number;
  }
];

export interface IDashboardMaintenancePlanFactStatistic {
  plans_for_the_month: TPlanFactStatistic;
  plans_for_the_day: TPlanFactStatistic;
  facts_for_the_day: TPlanFactStatistic;
}

export interface IYearPlanTransportsStat {
  month: number;
  completed_unique_maintenance_quantity: number;
  completed_maintenance_quantity: number;
  maintenance_quantity: number;
  remainder: number;
}

export interface IEquipmentTypeYearPlan {
  id: number;
  year: number;
  customer_id: number;
  customer: IDictionaryItem;
  unit_id: number;
  unit: IUnit;
  level: number;
  system_id: number;
  system: IDictionaryItem;
  equipment_type_id: 1;
  equipment_type: IEquipmentType | null;
  equipment_quantity: number;
  maintenance_quantity: number;
  completed_maintenance_quantity: number;
  enabled: boolean;
  plan_by_month: IEquipmentSystemSummaryMonth[];
}

export interface IPermission {
  result: boolean;
}

export interface ISlpiObjectMonthPlan {
  object: ISlpiObject;
}

export interface ISlpiDirectObject {
  system_set: {
    id: number;
    inventory_number: string | null;
    project_number: null | number;
    systems: IDictionaryItem[];
  };
  id: number;
  name: string | null;
  inventory_number: null | string;
  project_number: null | string;
  type: string;
  unit: number | null;
  customer: number | null;
  department: number | null;
  customer_department: number | null;
}

export type TSlpiDirectObjectLight = Omit<ISlpiDirectObject, "system_set">;

interface ITeamPlan {
  id: number;
  original_tasks: number[];
  tasks_snapshots: number[];
  created_at: string;
  updated_at: string;
}

export enum ShiftPlanStatusesEnum {
  DISAPPROVED = "DISAPPROVED",
  APPROVED = "APPROVED",
  CLOSED = "CLOSED",
  UNBLOCKED = "UNBLOCKED",
}

export interface IShiftPlan {
  id: number;
  unit_id: number;
  date: string;
  shift: number;
  shift_lead: number;
  created_at: string;
  updated_at: string;
  status: ShiftPlanStatusesEnum;
  team_plans: ITeamPlan[];
  unit_department_id: number | null;
}

export interface IChangeShiftPlanStatusPayload {
  unit_id: number;
  shift_num: number;
  date: string;
  comment?: string;
  status: ShiftPlanStatusesEnum;
  unit_department_id?: number;
}

export interface ISlpiDepartment extends IDictionaryItem {
  unit: number;
}

export interface ICustomerDepartment extends IDictionaryItem {
  mdm_code: any;
  customer_id?: number;
}

export interface ISlpiDepartmentExtended extends ISlpiDepartment {
  customer: IDictionaryItem;
}

export interface ISlpiObjectStat {
  plan: number;
  fact: number;
  facts_by_month: Record<number, [number, number]>;
}

export enum MaintenanceTypeEnum {
  MOUNTED = "mounted",
  STATIONARY = "stationary",
}

export interface ICheckMonthPlanPayload {
  unit_id: number | null;
  year: number;
  month: number;
  maintenance_type: "mounted" | "stationary";
}

export enum EquipmentSystemSummaryMonthStatusEnum {
  PLANNING = "PLANNING",
  APPROVE_WORKSHOP_LEAD = "APPROVE_WORKSHOP_LEAD",
  APPROVE_CUSTOMER = "APPROVE_CUSTOMER",
  APPROVED = "APPROVED",
}

export interface ICheckMonthPlanResult {
  error: string;
  is_approved: boolean;
  is_completed: boolean;
  unplanned_transports: [number, string][];
  status?: EquipmentSystemSummaryMonthStatusEnum;
}

export interface IEquipmentsTreeItem {
  node_id: string;
  node_type: string;
  subtree_type: null | string;
  data: {
    unit_id?: number;
    id?: number;
    name: string;
    has_malfunction?: boolean;
    has_problem?: boolean;
  };
  children?: IEquipmentsTreeItem[];
}

export interface IEquipmentMaintenance {
  equipment_id: number;
  equipment_type_id: number;
  maintenance_quantity: number;
  next_maintenance: null | {
    task_id: number | null;
    task_number: string;
    execution_date: string;
  };
  last_maintenance: null | {
    task_id: number | null;
    task_number: string;
    execution_date: string;
  };
}

export interface IEquipmentMaintenanceJournalItem {
  task_id: number;
  type: string;
  request_id: string | null;
  number: string | null;
  planned_date: string;
  closing_date: string | null;
  executor: {
    id: number;
    full_name: string;
  } | null;
  state: {
    id: number;
    name: string;
  };
  transport_id: number | null;
  equipment_id: number | null;
}

export interface IEquipmentCommentJournalItem {
  task_id: number;
  type: string;
  transport_id: null | number;
  equipment_id: null | number;
  closing_date: string;
  comment: string | null;
}

export interface IGroupTasks {
  location__id: null | number;
  location__name: null | string;
  maintenance_count: number;
  recovery_count: number;
  service_request_count: number;
  sum: number;
  tasks: number;
}

export interface IDashboardShiftStatisticComment {
  shift_lead: string;
  comment: string;
}

export interface IDashboardShiftStatistic {
  status?: ShiftPlanStatusesEnum;
  shift_lead?: string;
  teams_quantity?: number;
  employees_quantity?: number;
  comment_by_shift_lead: IDashboardShiftStatisticComment[];
  fact_workload_percentage?: number;
  planned_workload_percentage?: number;
}

export interface IDashboardShiftStatisticTasksByType {
  name: TaskTypesIdsEnum;
  sheduled_tasks: number;
  closed_sheduled_tasks: number;
  unsheduled_tasks: number;
  closed_unsheduled_tasks: number;
}

export interface IDashboardShiftStatisticTasksByTeam {
  by_type: IDashboardShiftStatisticTasksByType[];
  closed_sheduled_tasks: number;
  closed_unsheduled_tasks: number;
  id: string;
  name: string;
  sheduled_tasks: number;
  unsheduled_tasks: number;
}

export interface IDashboardShiftStatisticTasks {
  sheduled_tasks: number;
  closed_sheduled_tasks: number;
  closed_unsheduled_tasks: number;
  unsheduled_tasks: number;
  by_type?: IDashboardShiftStatisticTasksByType[];
  by_equipment_type?: {
    name: string;
    by_type: IDashboardShiftStatisticTasksByType[];
  }[];
  by_team?: IDashboardShiftStatisticTasksByTeam[];
}

export interface IDashboardShiftStatisticTasksByObjectsSlpi {
  sheduled_tasks: number;
  closed_sheduled_tasks: number;
  unsheduled_tasks: number;
  closed_unsheduled_tasks: number;
  by_type: IDashboardShiftStatisticTasksByType[];
  by_object: {
    by_type: IDashboardShiftStatisticTasksByType[];
    object: {
      id: number;
      name: string;
      inventory_number: string;
    };
  }[];
}

export enum OossaSystemTypeEnum {
  SMZIS = "SMZIS",
  ASKUE = "ASKUE",
  ASKUE_EE = "ASKUE_EE",
}

export interface IDashboardShiftStatisticTasksByObjectsOossa
  extends Omit<IDashboardShiftStatisticTasksByObjectsSlpi, "by_object"> {
  by_object: {
    by_type: IDashboardShiftStatisticTasksByType[];
    object: {
      object_id: number;
      object_name: string;
      system_id: number;
      system_name: string;
      code_enum: OossaSystemTypeEnum;
    };
  }[];
}

export interface IDashboardShiftStatisticTasksByEquipmentTypes {
  mounted: IDashboardShiftStatisticTasks;
  stationary: IDashboardShiftStatisticTasks;
}

interface IDashboardShiftStatisticTasksByTeamsTask {
  id: number;
  comment: string;
  description: string;
  note: string;
  elapsed_time: string;
  elapsed_time_multiplier: number;
  request_description: string;
  effort: string;
  closing_note: string;
  type: TaskTypesIdsEnum;
  request_comment: string;
  is_it_plan: boolean;
  status: string;
  original_status: string;
}

export interface IDashboardShiftStatisticTasksByTeamsPspaTask extends IDashboardShiftStatisticTasksByTeamsTask {
  is_mounted: boolean;
  transport?: string;
  equipment?: string;
  equipment_type_name?: string;
  system_name: string;
  work_type: string;
}

export interface IDashboardShiftStatisticTasksByTeamsSlpiTask extends IDashboardShiftStatisticTasksByTeamsTask {
  inventory_number: string;
  object: string;
  customer: string;
}

export interface IDashboardShiftStatisticTasksByTeamsOossaTask extends IDashboardShiftStatisticTasksByTeamsTask {
  work_type: string;
  system: string;
  location: string;
  oossa_object: string;
  oossa_tech_node: number;
  code_enum: OossaSystemTypeEnum;
}

export interface IDashboardShiftStatisticTasksByTeams<
  T extends
    | IDashboardShiftStatisticTasksByTeamsSlpiTask
    | IDashboardShiftStatisticTasksByTeamsPspaTask
    | IDashboardShiftStatisticTasksByTeamsOossaTask
> {
  tasks: Record<string, T[]>;
  team_name: string;
  team_id: string;
  disbanded: boolean;
  team_number?: number;
}

export interface IVideoHelp {
  id: number;
  chapter_name: string | null;
  content_type: string | null;
  description: string | null;
  title: string | null;
}

export interface IChangeCreateVacationOrSickLeaveDaysPayload {
  id: number;
  groupId: number;
  monthScheduleId: number;
  startDate: string;
  endDate: string;
  scheduleDayType: EmployeeScheduleDayTypeEnum;
}

export interface ICreateMaintenanceMountedTaskPayload {
  date: string;
  unit_id: number | null;
  transport_id: number;
  team_id: number | null;
}
export interface IPrintTasksFormProps {
  unitsNames?: (string | undefined)[];
  queryProps: {
    unit?: number[];
    date_from: string;
    date_to: string;
    tasks_url?: string;
  };
}

export interface IRatingBestEmployee {
  id: number;
  shoft_name: string;
  unit_id: number;
  unit: string;
}

export interface ITasksByShiftData {
  date: string;
  shift_number: number;
  task_quantity: number;
  task_effort: number;
}

export interface IRatingTreeData {
  employees_quantity_mean: number | null;
  rating: number | null;
  task_quantity: number | null;
  task_quantity_to_employees: number | null;
  task_effort_to_employees: number | null;
  recovery_task_effort_to_employees: number | null;
  maintenance_task_effort_to_employees: number | null;
  service_request_task_effort_to_employees: number | null;
  mounted_task_effort_to_employees: number | null;
  stationary_task_effort_to_employees: number | null;
  recovery_mounted_task_effort_to_employees: number | null;
  recovery_stationary_task_effort_to_employees: number | null;
  maintenance_mounted_task_effort_to_employees: number | null;
  maintenance_stationary_task_effort_to_employees: number | null;
  service_request_mounted_task_effort_to_employees: number | null;
  service_request_stationary_task_effort_to_employees: number | null;
  other_mounted_task_effort_to_employees: number | null;
  other_stationary_task_effort_to_employees: number | null;
  other_task_not_stationary_and_not_mounted_effort_to_employees: number | null;
  task_effort: number | null;
  task_effort_to_employees_rating: number | null;
  task_effort_to_employees_trend: number | null;
  task_effort_to_employees_trend_rating: number | null;
  task_effort_to_employees_union: number | null;
  task_effort_to_shift_duration: number | null;
  working_day_fact: number | null;
  working_day_fact_to_plan: number | null;
  working_day_plan: number | null;
}

export type TLeadRole = "shift_lead" | "unit_lead" | "team_lead";

export interface IRatingTree {
  id: number;
  name: string;
  data: IRatingTreeData;
  leads?: IRatingTree[];
  role: TLeadRole;
}

export interface IPerfomanceRatingHallOfFame {
  best_team_lead: IRatingBestEmployee[];
  best_shift_lead: IRatingBestEmployee[];
  best_unit_lead: IRatingBestEmployee[];
}

export interface IPerfomanceRatingTree {
  id: number;
  name: string;
  leads: IRatingTree[];
}

export interface IPerfomanceRatingTasksByShift {
  unit_id: number;
  unit_name: string;
  data: ITasksByShiftData[];
}

export interface IPerfomanceRatingResult {
  average_shift_tasks_quantity: number;
  hall_of_fame: {
    best_team_lead: IRatingBestEmployee[];
    best_shift_lead: IRatingBestEmployee[];
    best_unit_lead: IRatingBestEmployee[];
  };
  rating_tree: IPerfomanceRatingTree[];
  tasks_by_shift: IPerfomanceRatingTasksByShift[];
}

export interface ILeadsRating extends IRatingTreeData {
  team_lead_id?: number;
  shift_lead_id?: number;
  unit_names: string[];
  unit_lead_names: string[];
  shift_lead_names?: string[];
  team_lead_name?: string;
  shift_lead_name?: string;
  team_lead_names?: string[];
}

export interface ITeamsShiftTasksReportQueryProps {
  unit_id: number;
  date: string;
  shift_number: number;
  print_shift_tasks?: boolean;
  print_extra_tasks?: boolean;
  print_task_progress?: boolean;
  unit_department_id?: number;
}

export interface IUiPermissionBodyPayload {
  name: string;
  key: string;
}

export interface IUiPermission {
  is_allowed: boolean;
  key: TUiPermissionKey;
}

export enum SignatureSideEnum {
  Executor = "executor",
  Customer = "customer-representative",
}

export interface ICreateTaskSignaturePayload {
  side: SignatureSideEnum;
  taskId: number;
  body: FormData;
}

interface IExecutorSignature {
  id: number;
  executor: IEmployee;
  executor_id: number;
  signature: string;
}

export interface ICustomerRepresentativePayload {
  unit: number;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  position?: number;
}

export interface ICustomerRepresentative {
  id: number;
  unit: number;
  last_name: string;
  first_name: string;
  middle_name: string;
  full_name: string;
  position: number | null;
  position_name: string;
}

interface ICustomerSignature {
  id: number;
  customer_representative_id: number;
  customer_representative: ICustomerRepresentative;
  signature: string;
}

export interface ITaskSignature {
  id: number;
  customer_signature: ICustomerSignature | null;
  executor_signature: IExecutorSignature | null;
}

export enum PositionRoleEnum {
  CUSTOMER_REPRESENTATIVE = "CUSTOMER_REPRESENTATIVE",
  UNIT_LEAD = "UNIT_LEAD",
}

export interface IPosition extends IDictionaryItem {
  type: PositionRoleEnum;
  service: number;
}

export interface IGetTaskSignatureFileProps {
  taskId: number;
  side: SignatureSideEnum;
}

export interface IDetailObject {
  detail: string;
  default_code: string;
  default_detail: string;
}

export interface IError {
  data: {
    detail: string | IDetailObject;
    status_code: number;
    name?: string[];
  };
}

export interface IUnitPreferences {
  id: number;
  unit: number;
  key: string;
  name: string;
  preferences: any;
}

export enum ProcessingStatusEnum {
  UNPROCESSED = "UNPROCESSED",
  SKIPPED = "SKIPPED",
  IN_PROCESS = "IN_PROCESS",
  PROCESSED = "PROCESSED",
}

export interface IRecoveryTasksNotificationTask {
  id: string;
  number: string;
  description: string;
  priority: number;
  priority_label?: string;
  suspended_until?: string;
  request_work_time_usage_percent?: number;
  work_time_usage_hours?: number;
}

export enum RecoveryTasksNotificationEventEnum {
  NEW = "NEW",
  STATE_CHANGE_TO_WORK = "STATE_CHANGE_TO_WORK",
}

export interface IRecoveryTasksNotification {
  id: number;
  processing_status: ProcessingStatusEnum;
  event: RecoveryTasksNotificationEventEnum;
  task: IRecoveryTasksNotificationTask;
}

export interface ISkipRecoveryTasksNotificationBody {
  notification: number;
}

export interface IPlanTasksNotificationBody {
  notification: number;
  team?: number;
  date?: string;
  shift_number?: number;
}

export interface IPlanTasksNotificationError {
  data: {
    default_code: string;
    default_detail: string;
    detail: string;
    status_code: number;
  };
}

export interface IUserSettingsTasksNotifications {
  task_notifications_priority: 0 | 1 | 2 | 3;
  task_notifications_enable: boolean;
}

export interface IFetchErrorData {
  detail: string;
  traceback: string[];
  status_code: number;
  default_detail: string;
  default_code: string;
}

export enum CreatioTaskTypeEnum {
  RECOVERY = "incident_task",
  SERVICE_REQUEST = "sc_task",
}

export interface IItsmCategoryGroup {
  id: string;
  name: string;
  type: CreatioTaskTypeEnum;
  is_mounted: boolean;
  is_stpp: boolean;
}

export interface ILocalAttachment {
  uid: string;
  mimeType: string;
  originalName: string;
  file: string;
  size: number;
  lastModifiedDate: Date;
}

export interface ISendCreatioTaskBody {
  type: CreatioTaskTypeEnum;
  unit: number;
  request_category: string;
  transport?: number | null;
  equipment_type: number | null;
  mobile_brigade_equipment?: number | null;
  itsm_equipment?: string | null;
  itsm_stpp_equipment_type?: string | null;
  description: string;
  attachments?: ILocalAttachment[];
  location: number | null;
  effort?: number | null;
  effort_duration?: string | null;
  team_id?: number | null;
  shift_number?: 1 | 2 | 3 | null;
  planned_date?: string;
}

export type TSendOtherPspaTaskBody = Partial<
  Pick<
    ITask,
    | "equipment_type_id"
    | "location_id"
    | "equipment_id"
    | "effort"
    | "transport_id"
    | "request_description"
    | "attachments"
    | "work_type_id"
    | "unit_id"
    | "team_id"
    | "shift_number"
    | "planned_date"
    | "state_id"
    | "service_id"
    | "type"
    | "request_creation_datetime"
    | "is_mounted"
    | "effort_duration"
  >
>;

export type TSendOtherSlpiTaskBody = Partial<
  | Pick<
      ITask,
      | "effort"
      | "request_description"
      | "attachments"
      | "work_type_id"
      | "unit_id"
      | "team_id"
      | "shift_number"
      | "planned_date"
      | "state_id"
      | "service_id"
      | "type"
      | "request_creation_datetime"
      | "is_mounted"
      | "customer_id"
      | "customer_department_id"
      | "object_id"
      | "system_complex_id"
    > & { month_plan_name: string }
>;

export type TSendOtherLsoTaskBody = Partial<
  | Pick<
      ILsoTask,
      | "effort"
      | "request_description"
      | "attachments"
      | "work_type_id"
      | "unit_id"
      | "team_id"
      | "shift_number"
      | "planned_date"
      | "state_id"
      | "service_id"
      | "type"
      | "request_creation_datetime"
      | "customer_id"
      | "customer_department_id"
      | "lso_object_id"
    > & { month_plan_name: string }
>;

export type TSendOtherOossaTaskBody = Partial<
  Pick<
    ITask,
    | "oossa_object_id"
    | "oossa_equipment_id"
    | "oossa_tech_node_id"
    | "customer_department_id"
    | "system_id"
    | "type"
    | "state_id"
    | "team_id"
    | "planned_date"
    | "effort"
    | "unit_id"
    | "shift_number"
    | "work_type_id"
    | "service_id"
    | "request_creation_datetime"
    | "attachments"
    | "request_description"
    | "effort_duration"
  >
>;

export interface IItsmCategory {
  id: string;
  is_deactivated: boolean;
  itsm_category_group: string;
  name: string;
  required_customer_approval: boolean;
}

export interface IWhoami {
  username: string;
  full_name: string;
  position: string;
  roles: RoleEnum[];
}

export interface IWorkRegime {
  id: number;
  name: string;
  description: string;
  begin: string;
  end: string;
  duration: string;
  number: 1 | 2 | 3;
  customer_number: number;
  code_enum: ShiftSlugsEnum;
  badge_name?: string;
}

export interface ICreateMonthPlansPayload {
  year: number;
  unit: number;
  customer: number;
  month: number;
  is_mounted: boolean;
}

export interface ICreateMonthPlansResponse {
  is_completed: boolean;
}

export interface IItsmServiceOfferinge {
  id: string;
  name: string;
}

export interface IDailySummaryReportField {
  id: number;
  category: string;
  name: string;
  input_type: string; // "READ_ONLY" | "USER_INPUT" | "PARTIAL_USER_INPUT";
  original_value: string | null;
  current_value: string | null;
}

export interface IDailySummaryReport {
  id: number;
  report_date: string;
  report_fields: {
    employee_stats: {
      total_count: IDailySummaryReportField;
      vacation_count: IDailySummaryReportField;
      outshift_count: IDailySummaryReportField;
      working_count: IDailySummaryReportField;
      other_count: IDailySummaryReportField;
    };
    underground_employee_stats: {
      total_count: IDailySummaryReportField;
      vacation_count: IDailySummaryReportField;
      outshift_count: IDailySummaryReportField;
      working_count: IDailySummaryReportField;
      other_count: IDailySummaryReportField;
    };
    daily_stationary_equipment_task_stats: {
      total_count: IDailySummaryReportField;
      incoming_count: IDailySummaryReportField;
      completed_count: IDailySummaryReportField;
    };
    daily_mounted_equipment_task_stats: {
      total_count: IDailySummaryReportField;
      incoming_count: IDailySummaryReportField;
      completed_count: IDailySummaryReportField;
    };
    daily_video_registration_equipment_task_stats: {
      total_count: IDailySummaryReportField;
      incoming_count: IDailySummaryReportField;
      completed_count: IDailySummaryReportField;
    };
    daily_aerogas_protection_equipment_task_stats: {
      total_count: IDailySummaryReportField;
      incoming_count: IDailySummaryReportField;
      completed_count: IDailySummaryReportField;
    };
    daily_telephony_equipment_task_stats: {
      total_count: IDailySummaryReportField;
      incoming_count: IDailySummaryReportField;
      completed_count: IDailySummaryReportField;
    };
    backlog_task_stats: {
      prebacklog_tasks_3_avr_count: IDailySummaryReportField;
      prebacklog_tasks_2_avr_count: IDailySummaryReportField;
      prebacklog_tasks_1_avr_count: IDailySummaryReportField;
      backlog_tasks_avr_count: IDailySummaryReportField;
      prebacklog_tasks_3_zno_count: IDailySummaryReportField;
      prebacklog_tasks_2_zno_count: IDailySummaryReportField;
      prebacklog_tasks_1_zno_count: IDailySummaryReportField;
      backlog_tasks_zno_count: IDailySummaryReportField;
    };
    stationary_equipment_assembling_stats: {
      stationary_equipment_installation_total_count: IDailySummaryReportField;
      stationary_equipment_installation_completed_count: IDailySummaryReportField;
      mounted_equipment_installation_total_count: IDailySummaryReportField;
      mounted_equipment_installation_completed_count: IDailySummaryReportField;
      equipment_vandalism_count: IDailySummaryReportField;
    };
    team_stats: {
      night_shift_team_count?: IDailySummaryReportField;
      night_shift_employee_count?: IDailySummaryReportField;
      day_shift_team_count?: IDailySummaryReportField;
      day_shift_employee_count?: IDailySummaryReportField;
      evening_shift_employee_count?: IDailySummaryReportField;
      evening_shift_team_count?: IDailySummaryReportField;
    };
    stationary_equipment_work_in_progress_stats: {
      idgr_in_progress_count: IDailySummaryReportField;
      idgr_total_count: IDailySummaryReportField;
      zsk_in_progress_count: IDailySummaryReportField;
      zsk_total_count: IDailySummaryReportField;
      stpp_in_progress_count: IDailySummaryReportField;
      stpp_total_count: IDailySummaryReportField;
    };
    mounted_equipment_work_in_progress_stats: {
      idgr_in_progress_count: IDailySummaryReportField;
      idgr_total_count: IDailySummaryReportField;
      zsk_in_progress_count: IDailySummaryReportField;
      zsk_total_count: IDailySummaryReportField;
      stpp_in_progress_count: IDailySummaryReportField;
      stpp_total_count: IDailySummaryReportField;
    };
    equipment_maintenance_stats: {
      stationary_equipment_fact: IDailySummaryReportField;
      mounted_equipment_fact: IDailySummaryReportField;
      stationary_equipment_plan: IDailySummaryReportField;
      mounted_equipment_plan: IDailySummaryReportField;
    };
    telephony_stats: {
      installed_count: IDailySummaryReportField;
      verified_count: IDailySummaryReportField;
      reattached_count: IDailySummaryReportField;
    };
    transport_stats: {
      transport_comment: IDailySummaryReportField;
    };
    other: {
      extra_comment: IDailySummaryReportField;
      main_comment: IDailySummaryReportField;
    };
  };
}

export interface IDailySummaryReportExist {
  is_exist: true;
}

export interface IDailySummaryReportBodyField {
  field: number;
  value: string | number;
}

export interface IUpdateDailySummaryReportBody {
  report: number;
  fields: IDailySummaryReportBodyField[];
}

export interface IKanbanColumn {
  column: string;
  tasks: ITask[];
}

export interface IDashboardSlpiMaintenancePlanFactStatistic {
  plans_for_the_month: number;
  plans_for_the_day: number;
  facts_for_the_day: number;
}

export interface IAvailableShiftPlanStatusesQueryPayload {
  unit_id: number | null;
  date: string;
  shift_num: number;
}

export interface IAvailableShiftPlanStatuses {
  available_statuses: ShiftPlanStatusesEnum[];
  current_status: ShiftPlanStatusesEnum;
  desc: string;
}

export interface IOossaYearPlansQueryPayload {
  year: number;
  customer_department_id?: number;
  system_ids?: string;
  name?: string;
  location_id?: number;
  limit?: number;
}

export interface IOossaYearPlansStat {
  equipments: number;
  facts: number;
  objects: number;
  plan: number;
}

export interface IPlanByMonth {
  id: number;
  completed_maintenance_quantity: number;
  maintenance_quantity: number;
  approve_date: string | null;
  month: number;
}

export type TOossaMonthPlanState = Pick<IPlanByMonth, "month" | "approve_date">;

export interface IOossaObjectTag extends IDictionaryItem {
  code_enum: OossaSystemTypeEnum;
}

export interface IPlanBySystem {
  effort_norm: number;
  equipment_quantity: number;
  id: number;
  maintenance_quantity: number;
  completed_maintenance_quantity: number;
  plan_by_month: IPlanByMonth[];
  system: IOossaObjectTag;
  object: number;
  equipment_type: {
    is_mounted: boolean;
    // maintenance_quantity: null | number;
    name: string;
    system: null | number;
  };
}

export interface IOossaYearPlan {
  equipment_quantity: number;
  maintenance_quantity: number;
  object: IDictionaryItem;
  plan_by_month: IPlanByMonth[];
  plan_by_systems: IPlanBySystem[];
  object_tags: IOossaObjectTag[];
}

export interface IOossaMonthPlanEquipment {
  id: number;
  manufacturer: IDictionaryItem;
  equipment_type: IEquipmentType;
  name: string;
  serial_number: string;
  model: string;
  verification_date: string;
  manufactured_date: string;
  nominal_diameter: string;
  outdoor: boolean;
  accuracy_class: string;
  transformation_ratio: string;
  power_phases: string;
  connection_type: string;
  tech_node: number;
}

/** Технический узел ООССА. */
export interface IOossaTechNode {
  id: number;
  name: string;
  address: IAddress;
  address_id: number;
  height: string;
  object: object;
  object_id: number;
  system: IOossaObjectTag;
  system_id: number;
  oossaequipments: IOossaMonthPlanEquipment[];
}

export interface IOossaMonthPlan {
  id: number;
  scheduled_days?: number[];
  recovery_days?: number[];
  repaired_days?: number[];
  work_in_progress_days?: number[];
  completed_days?: number[];
  tasks?: Record<number, number>;
  tech_node: IOossaTechNode;
  equipment_types: IMonthPlanEquipmentType[];
  last_closed_task?: {
    id: ITask["id"];
    executor: ITask["maintenance_executor"];
    comment: ITask["comment"];
  };
}

export interface IOossaMonthPlanQueryPayload {
  year_plan_id: number;
  object_id: number;
  oossa_system_id: number;
  month: number;
}

export interface IOossaMonthPlansCreateQueryPayload {
  year_plan_id: number;
  month: number;
}

export interface IApproveOossaMonthPlanPayload {
  year: number;
  system_id: number | string;
  month: number;
}

export interface IApproveOossaMonthPlan {
  tasks?: number[];
  errors?: string[];
}

export interface IOossaYearPlanByMonths extends IDictionaryItem {
  months: { approve_date: null | string; month: number }[];
}

export interface IExtendPreviousMonthQueryPayload {
  id: number;
  prevMonth: {
    month_schedule: {
      unit_id: number | null;
      year: number;
      month: number;
    };
  };
}

export interface ICheckSlpiMonthPlan {
  data?: string[];
  result: boolean;
}

export interface ITeamTasks extends Omit<ITeam, "team_lead_id"> {
  tasks: ITask[];
}

export interface IGetTaskQueryPayload {
  taskId: string | number;
  slice: TaskApiSlice | null;
  query?: string;
}

export interface IGetTasksQueryPayload {
  url: string;
  queryProps: string;
}

export enum SlpiWorkTypeGroupEnum {
  RESEARCH = "research",
  WORK = "work",
  INSTRUCTION = "instruction",
}

export interface ISlpiWorkType extends IDictionaryItem {
  description: string;
  group: SlpiWorkTypeGroupEnum;
}

export interface IUnitDepartment extends IDictionaryItem {
  unit_id: number | null;
}

export type TQueryProps = Record<string, string | number | number[] | boolean>;
export type TQueryPropsWithStringifyOptions = Record<string, string | number | number[] | boolean | IStringifyOptions>;

export interface IApproveMonthPlanHistory {
  status: EquipmentSystemSummaryMonthStatusEnum;
  year: number;
  unit: number;
  month: number;
  is_mounted: boolean;
  approval_customer_date: string;
  approved_by: number;
}

export interface IApproveMonthPlanHistoryPayload {
  year: number;
  unit: number;
  month: number;
  is_mounted: boolean;
}

export interface IRepairArea extends IDictionaryItem {
  unit_id: number | null;
}

export interface IGetPspaMonthPlanSignatureProps {
  year: number;
  is_mounted: boolean;
  unit: number;
  month: number;
}

export interface IPspaMonthPlanSignature {
  customer_representative_signature: null | string;
  id: number;
  is_mounted: boolean;
  month: number;
  unit: number;
  unit_lead_signature: null | string;
  work_shop_lead_signature: null | string;

  year: number;
}

export enum PspaMonthPlanSignatureUrlPartEnum {
  CUSTOMER = "customer-representative",
  UNIT_LEAD = "unit-lead",
  WORK_SHOP_LEAD = "work-shop-lead",
}

export interface IUpdatePspaMonthPlanSignatureProps {
  id: number;
  urlPart: PspaMonthPlanSignatureUrlPartEnum;
  body: FormData;
}

export interface ICreateSlpiMonthPlansPayload {
  unit_id: number;
}

export interface IOossaMaintenancePlanFactStatisticBySystem {
  system: OossaSystemTypeEnum;
  amount: number;
  repeated: number;
}

export interface IOossaMaintenancePlanFactStatistic {
  plans_for_the_month: IOossaMaintenancePlanFactStatisticBySystem[];
  plans_for_the_day: IOossaMaintenancePlanFactStatisticBySystem[];
  facts_for_the_day: IOossaMaintenancePlanFactStatisticBySystem[];
}

export enum OossaWorkTypeGroupEnum {
  OTHER = "other",
  REPAIR = "repair",
}

export interface IOossaWorkType extends IDictionaryItem {
  group: OossaWorkTypeGroupEnum;
}

export interface ISlpiMonthPlansAndEffort {
  month_plan_names: {
    name: string;
  }[];
  effort: number | null;
}

export interface IMonth<T> {
  "1": T;
  "2": T;
  "3": T;
  "4": T;
  "5": T;
  "6": T;
  "7": T;
  "8": T;
  "9": T;
  "10": T;
  "11": T;
  "12": T;
  "13": T;
  "14": T;
  "15": T;
  "16": T;
  "17": T;
  "18": T;
  "19": T;
  "20": T;
  "21": T;
  "22": T;
  "23": T;
  "24": T;
  "25": T;
  "26": T;
  "27": T;
  "28": T;
  "29"?: T;
  "30"?: T;
  "31"?: T;
}

export interface IScheduleStatsEmployeesTypes {
  ALL: IMonth<number>;
  DAY?: IMonth<number>;
  EVENING?: IMonth<number>;
  NIGHT?: IMonth<number>;
  DAY_2?: IMonth<number>;
  NIGHT_2?: IMonth<number>;
}

export interface IScheduleStatsEmployees {
  COUNT_EMPLOYEES: IScheduleStatsEmployeesTypes;
  FOREMAN: IScheduleStatsEmployeesTypes;
  DRIVER: IScheduleStatsEmployeesTypes;
  AVG_EMPLOYEES: IScheduleStatsEmployeesTypes;
}

// export interface EtoTask {
//   attachments: { shift_start: []; shift_end: []; other: [] };
//   battery_water_added: number;
//   closing_date: string | null;
//   closing_note: string;
//   comment: string;
//   created_at: string;
//   current_step_id: number;
//   customer: null;
//   customer_id: number | null;
//   discriminator: TaskDiscriminator;
//   engine_oil_added: number;
//   fuel_added: number;
//   hydraulic_tank_water_added: number;
//   id: number;
//   maintenance_executor: null;
//   maintenance_executor_id: number | null;
//   note: string;
//   number: string;
//   planned_date: string;
//   service: Service;
//   service_id: number;
//   shift_number: number;
//   state: DictionaryItem;
//   state_id: number;
//   steps: EtoTaskStep[];
//   team: Team;
//   team_id: number;
//   transmission_oil_added: number;
//   transport: Transport;
//   transport_id: number;
//   transport_status_from_driver: TransportStatus;
//   type: string;
//   unit: Unit;
//   unit_id: number;
//   updated_at: string;
// }

export interface IUpdateEtoStepPayload {
  taskId: number;
  step: IEtoTaskStep;
}

export interface IEtoMonthPlanStat {
  month: number;
  plan: number;
  fact: number;
  is_approved: boolean;
}

export interface ICreateEtoMonthPlansPayload {
  year: number;
  month: number;
  unit_id: number;
}

export interface IEtoPlanShiftsFact {
  data?: {
    task?: {
      is_completed: false;
      transport_status_from_driver: TransportStatusEnum;
    };
    task_id?: number;
  };
  day: number;
  shift_number: number;
}

export interface IEtoMonthPlanScheduledShift {
  locked: boolean;
  planned: boolean;
}

export type TEtoMonthPlanScheduledShifts = Record<number, IEtoMonthPlanScheduledShift>;

export interface IEtoMonthPlan {
  available_shifts: number[];
  id: number;
  is_approved: boolean;
  month: number;
  scheduled_shifts: IMonth<TEtoMonthPlanScheduledShifts>;
  shifts_facts: IEtoPlanShiftsFact[];
  transport?: IEtoTransport;
  transport_id: number;
  year: number;
}

export interface IUpdateEtoMonthPlanPayload {
  id: number;
  scheduled_shifts: any;
}

export interface ICreateEtoStepSignaturePayload {
  id: number;
  body: FormData;
  taskId: number;
}

export interface IEmployeeSignature {
  id: number;
  signature: string;
  executor: IEmployee;
  executor_id: number;
}

export interface IMaintenanceType extends IDictionaryItem {
  service: IService;
}
