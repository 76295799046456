import { ApiSliceEnum } from "core/app/api/enums";
import { useUnit } from "core/shared/unit/useUnit";

/**
 * Слайсы(разделы) апишки на основании услуги, для получения специфичных для услуги данных.
 * Например, задачи по оосса получаем из точки oossa-tasks, для слпи из slpi-tasks.
 */

export function useApiSlice() {
  const unit = useUnit();
  let slice: null | ApiSliceEnum = null;
  if (unit?.isOOSSA()) {
    slice = ApiSliceEnum.oossa;
  }
  if (unit?.isPSPA() /* для isPSPAZF */) {
    slice = ApiSliceEnum.pspa;
  }
  if (unit?.isSLPI()) {
    slice = ApiSliceEnum.slpi;
  }
  if (unit?.isLSO()) {
    slice = ApiSliceEnum.lso;
  }
  if (unit?.isPSPAKF()) {
    slice = ApiSliceEnum.pspaKf;
  }
  if (unit?.isLKU()) {
    slice = ApiSliceEnum.lku;
  }
  return slice;
}
