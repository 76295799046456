import { Modal, Row, Spin } from "antd";
import { ReactElement } from "react";

import { useAppSelector, useAppDispatch } from "core/app/store";
import { TaskTypesIdsEnum, TaskDiscriminatorEnum } from "core/app/api/interfaces";
import { useSignatureStatus } from "core/shared/task/useSignatureStatus";
import { setFindOrCreate } from "core/app/store/find-or-create-slice";
import { useTask } from "core/shared/task/useTask";
import OossaMaintenanceTaskContent from "core/widgets/Oossa/OossaMaintenanceTaskContent";
import { useTaskId } from "core/shared/task/useTaskId";

import AssistanceCarTaskModal from "../AssistanceCarTaskModal";
import LsoTaskModal from "../LsoTaskModal";

import MountedTaskForm from "./Mounted";
import StationaryTaskModal from "./Stationary";
import RecoveryTaskModal from "./Recovery";
import OtherTaskModal from "./Other";
import SgpsMountedTaskForm from "./SGPS/Mounted";

/**
 * Корневой компонент для всех задач открываемых в модальном окне.
 * Предоставляет непосредственно модальное окно, логику его открытия, загрузку задачи,
 * спиннер на период загрузки.
 * По типу загруженной задачи, определяет необходимый компонент для отображения содержимого окна.
 */
function TaskModal(): ReactElement {
  const [taskId, , moment, resetState] = useTaskId();
  const { findOrCreate } = useAppSelector((state) => state.findOrCreate);
  const { data: task } = useTask(null, moment);
  const [signatureStatus, setSignatureStatus] = useSignatureStatus();
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    if (signatureStatus) {
      setSignatureStatus(null);
    } else {
      resetState();
      dispatch(setFindOrCreate(null));
    }
  };
  return (
    <Modal
      maskClosable={false}
      destroyOnClose
      footer={null}
      open={!!taskId || !!findOrCreate}
      width={730}
      onCancel={handleCancel}
    >
      {/* @TODO Наименования классов не отражают суть компонента, переименовать и отрефакторить стили */}
      <div className="task-modal recovery-task-modal maintenance-attached-equipment-modal">
        {task && (
          <>
            {task.type === TaskTypesIdsEnum.MAINTENANCE && (
              <>
                {task.discriminator === TaskDiscriminatorEnum.LSO_TASK && <LsoTaskModal />}
                {task.discriminator === TaskDiscriminatorEnum.OOSSA_TASK && <OossaMaintenanceTaskContent />}
                {task.discriminator === TaskDiscriminatorEnum.SLPI_TASK && <StationaryTaskModal />}
                {task.discriminator === TaskDiscriminatorEnum.PSPA_TASK && (
                  <div>{task.is_mounted === true ? <MountedTaskForm /> : <StationaryTaskModal />}</div>
                )}
                {task.discriminator === TaskDiscriminatorEnum.PSPA_KF_TASK && (
                  <div>{task.is_mounted === true ? <SgpsMountedTaskForm /> : <StationaryTaskModal />}</div>
                )}
              </>
            )}
            {(task.type === TaskTypesIdsEnum.RECOVERY || task.type === TaskTypesIdsEnum.SERVICE_REQUEST) && (
              <RecoveryTaskModal />
            )}
            {task.type === TaskTypesIdsEnum.OTHER && <OtherTaskModal />}
            {task.type === TaskTypesIdsEnum.ETO && <AssistanceCarTaskModal />}
          </>
        )}
        {!task && (
          <Row align="middle" justify="center" style={{ minHeight: "300px" }}>
            <Spin size="large" />
          </Row>
        )}
      </div>
    </Modal>
  );
}

export { TaskModal };
